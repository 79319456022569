export const en = {
  "Enter the correct address" : "Enter the correct address",
  poolDisclaimer: "The information provided is in no way a recommendation as to whether you should contribute in any product discussed. The GAGARIN platform does not collect or manage contributor funds.",
  "website": "website",
  "participate" : "contribute",
  "Vesting" : "Vesting",
  "Learn more" : "Learn more",
  "Whitelist start": "Whitelist start",
  "Whitelist end": "Whitelist end",
  "Swap start": "Swap start",
  "Swap end": "Swap end",
  "FCFS end": "FCFS end",
  "Next claim": "Next claim",
  "Payments are over": "Payments are over",
  "Whitelist starts on": "Whitelist starts on",
  "Whitelist ends on": "Whitelist ends on",
  "Swap starts on": "Swap starts on",
  "Swap ends on": "Swap ends on",
  "FCFS ends on": "FCFS ends on",
  "Whitelist starts after": "Whitelist starts after",
  "Whitelist ends after": "Whitelist ends after",
  "Swap starts after": "Swap starts after",
  "Swap ends after": "Swap ends after",
  "FCFS ends after": "FCFS ends after",
  "Next claim at": "Next claim at",
  "Exchange Rate": "Exchange Rate",
  "Total Raise": "Total Raise",
  "IDO Launchpad": "IDO Launchpad",
  "INO Launchpad": "INO Launchpad",
  "Box Count": "Boxes Count",
  "One Lootbox": "One Lootbox",
  "Gagarin Total raise" : "Gagarin Total raise",
  from: "from",
  Refund: "Refund",
  Refunded: "Refunded",
  Pools: "GFT clients",
  "Number of investors": "number of contributors",
  "Total raise": "total raise",
  "Tokenomics": "Tokenomics",
  "Whitepaper": "Whitepaper",
  "Pitchdeck": "Pitchdeck",
  "invest": "Go ahead and contribute",

  tg: {
    questions: "Do you have a question? <1></1> Ask it in our chat!",
  },
  header: {
    projects: "For projects",
    "Gagarin.world": "Gagarin.world",
    about: "About",
    launchpad: "Launchpad",
    submit: "Submit your project",
    apply: "Submit an application",
  },
  hero: {
    title:
      "<0> GAGARIN Fundraising portal </0> <2></2>Discover the best early-stage <2></2> project token sales",
    desc: "Become an early contributor in the most innovative projects in Web3. <1></1> Buy project tokens at an early stage at the best price directly on projects' websites",
  },
  pools: {
    title: "Pools",
    disclaimer:
      "Disclaimer:  <1>*</1> The information provided is in no way a recommendation as to whether you should invest in any product discussed. The GAGARIN platform does not collect or manage investor funds. All funds that you invest in the fundraising pool are transferred directly to the project",
  },
  projects: {
    title:
      "Go to our launchpad, participate <1></1> in major projects and earn!",
  },
  activities: {
    title: "Participate in our activities",
  },
  faq: {
    title: "FAQ",
    _1: {
      title: "What is GAGARIN Fundraising Portal?",
      desc: "This is a platform designed to directly attract investment in crypto startups. Fundraising is carried out using the GAGARIN Fundrasing Tool, a special tool from the GAGARIN ecosystem. It is a form that is embedded on the project website and launches fundraising on the site. It is enough for an investor to go to the GAGARIN Fundrasing Portal, select the project they like, connect the wallet and transfer funds to buy project tokens.",
    },
    _2: {
      title: "What are the benefits of GAGARIN Fundraising Portal?",
      desc: `— For crypto startup teams, the GAGARIN Fundraising Portal offers affordable functionality for raising funds for the implementation and development of their ideas, as well as a formed community of potential investors. <0></0> <0></0>
             Benefits for investors: <0></0>
             — the investor gets access to early sales of crypto startups on one platform. <0></0>
             — significantly increases the range of choice of startups on which you can earn.
            `,
    },
    _3: {
      title:
        "What is the difference between GAGARIN Fundraising Portal and GAGARIN launchpad?",
      desc: `— Both platforms help startups in attracting investments for development, but differ in the scheme for transferring funds.
            <0></0> <0></0>
            — On the GAGARIN launchpad, funds are first accumulated on its account, so GAGARIN can control the flow and distribution of assets and is responsible for them. The investments raised from the GAGARIN Fundraising Portal are directly available to the projects. The GAGARIN team does not control or distribute the assets collected on the portal.
            <0></0>
            — Therefore, the investor protection mechanism that operates on the launchpad does not work on the portal.
            `,
    },
    _4: {
      title: "Where can I read more about this?",
      desc: `More information about the Fundraising Portal project can be found in the <1>GAGARIN ACADEMY on  gitbook</1>.`,
    },
    _5: {
      title:
        "How safe is it to contribute through the GAGARIN Fundraising portal?",
      desc: `All projects whose pools are listed on gfp.gagarin.world use Gagarin Fundraising Tool. The information provided is in no way a recommendation as to whether you should contribute in any product discussed. The GAGARIN platform does not collect or manage contributor funds. All funds that you contributor in the fundraising pool are transferred directly to the project.`,
    },

  },
  bottom: {
    title1: "List your project on GAGARIN Fundraising portal",
    title2: "Get news about new projects by email",
    download: "Download the presentation",
    apply: "Apply now",
    mail: "Receive",
  },
  footer: {
    title: "Join our community",
    socials: "Join us",
    community: {
      title: "Become part of our community",
      investor: "Become an investor",
      expert: "Become an expert",
      partner: "Become a partner",
      ambassador: "Become an ambassador",
    },
    ido: "Apply for IDO",
    ino: "Apply for INO",
    privacy: "Privacy Policy",
    service: "Terms of Service",
    docs: "Documentation",
    whitepaper: "Whitepaper",
    tokenomics: "Tokenomics",
    incubation: "Apply for Incubation",
    allLinks: "Links to all resources",
    referral: "Apply to Global referral <1></1> program",
    announces: "Announcement channels"

  },
};
