import { BigNumber } from "ethers";
import { formatEther, formatUnits, parseUnits } from "ethers/lib/utils";
import { RefObject, useEffect, useMemo, useState } from "react";
import { BaseProject, Ido, ImageType, Ino, isIdo, MarketingActivityType, ProjectDates, ProjectStatus } from "../../sdk";
import { ACTIVITIES_ICON_MAP } from "../constants";
import { ActivityStatus, ChainId, CurrencyTicket, ProjectStage } from "../enums";
import iBusd from "../../assets/img/tokens/busd.svg";
import iUsdt from "../../assets/img/tokens/usdt.png";
import i18next from "i18next";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_SHORT, Project, ProjectStages } from "../types";
import i18n from "../i18n";
export function getNumberWithSpaces(number: string) {
    var parts = number.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

export const formatBigNumber = (
    number: string | number | BigNumber | undefined
  ): string => {
    if (!number) return "0";
    let formatted = formatUnits(number, 18);
    const index = formatted.indexOf(".");
    if (formatted.length - index - 1 > 3) {
      formatted = formatted.slice(0, index + 3);
    }
    if (formatted.endsWith(".0")) {
      formatted = formatted.slice(0, -2);
    }
    return getNumberWithSpaces(formatted);
  };

  export const useProjectInvestedPercent = (project: Ido): number => {
    return useMemo(() => {
      if (!project || !project.invested || !project.totalRaise) {
        return 0;
      }
      const invested = parseInt(formatEther(project.invested || "0"));
      const totalRaise = parseInt(formatEther(project.totalRaise || "0"));
      if (invested > 0) {
        return Math.round((invested / totalRaise) * 100);
      }
      return 0;
    }, [project]);
  };
  export const getNextClaimDateString = (
      index: number,
      project?: Ido
    ): string => {
      if (!project || !project.payments?.length) return "TBA";
      if (index > project.payments.length - 1) return PAYMENTS_OVER;
    
      if (Date.now() >= project.payments[index].date) {
        return getNextClaimDateString(index + 1, project);
      } else {
        return formatDate(project.payments[index].date);
      }
    };
  
  export const useProjectTotalSwap = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.totalRaise || !project.exchangeRate) return "TBA";
      const totalRaise = parseUnits(project.totalRaise || "0");
      const exchangeRate = parseUnits(project.exchangeRate);
      const totalSwap = totalRaise.mul(parseUnits("1")).div(exchangeRate);
      return formatBigNumber(totalSwap);
    }, [project]);
  };
  
  export const useProjectFill = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.invested || !project.exchangeRate) return "0";
      const totalInvested = parseUnits(project.invested);
      const exchangeRate = parseUnits(project.exchangeRate);
      const totalFill = totalInvested.mul(parseUnits("1")).div(exchangeRate);
      return formatBigNumber(totalFill);
    }, [project]);
  };
  export const useLeftToRaise = (project?: Ido): BigNumber => {
    return useMemo(() => {
      if (!project) return BigNumber.from(1); // на всякий случай
      const totalRaise = BigNumber.from(project.totalRaise || '0');
      const invested = BigNumber.from(project.invested);
  
      return totalRaise.sub(invested);
    }, [project]);
  };
  const isFullDate = (timestamp: number): boolean => {
    const seconds = new Date(timestamp).getSeconds();
    return seconds === 0;
  };
  export const formatDate = (timestamp: number): string => {
    const date = moment(timestamp).locale(i18next.language);
    if (isFullDate(timestamp)) {
      return date.format(DEFAULT_DATE_FORMAT);
    }
    return date.format(DEFAULT_DATE_FORMAT_SHORT);
  };
  export const useProjectWhitelistStart = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.whitelistStartDate) return "TBA";
      return formatDate(project.whitelistStartDate);
    }, [project,i18n.language]);
  };
  export const useProjectWhitelistEnd = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.whitelistEndDate) return "TBA";
      return formatDate(project.whitelistEndDate);
    }, [project]);
  };
  export const useProjectSwapStart = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.swapStartDate) return "TBA";
      return formatDate(project.swapStartDate);
    }, [project]);
  };
  export const useProjectSwapEnd = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.swapEndDate) return "TBA";
      return formatDate(project.swapEndDate);
    }, [project]);
  };
  export const useProjectFcfsEnd = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.fcfsEndDate) return "TBA";
      return formatDate(project.fcfsEndDate);
    }, [project]);
  };
  export const getNextClaimByProject = (
    project?: Ido,
    index: number = 0
  ): number | "TBA" | typeof PAYMENTS_OVER => {
    if (!project || !project.payments?.length) return "TBA";
    if (index > project.payments.length - 1) return PAYMENTS_OVER;
  
    if (Date.now() >= project.payments[index].date) {
      return getNextClaimByProject(project, index + 1);
    } else {
      return project.payments[index].date;
    }
  };
  
  export const useNextClaimByProject = (
    project?: Ido,
    index: number = 0
  ) => {
    return useMemo(() => {
      const nextClaim = getNextClaimByProject(project, index);
      let nextClaimView = "TBA";
      let nextClaimDate = 0;
  
      if (typeof nextClaim === "number") {
        nextClaimDate = nextClaim;
        nextClaimView = formatDate(nextClaim);
      } else {
        nextClaimView = nextClaim;
      }
      return { nextClaimView, nextClaimDate };
    }, [project]);
  };
  
  export const PAYMENTS_OVER = "Payments are over";
  
  export const useProjectStage = (project?: Ido): ProjectStages => {
    const leftToRaiseAmount = useLeftToRaise(project);
  
    return useMemo(() => {
      if (!project) return ProjectStages.whitelistTba;
      if (!project.whitelistStartDate) {
        return ProjectStages.whitelistTba;
      } else if (
        project.status === "not deployed" &&
        Date.now() < project.whitelistStartDate
      ) {
        return ProjectStages.whitelistGap;
      } else if (
        project.status === "not deployed" &&
        Date.now() >= project.whitelistStartDate &&
        Date.now() < project.whitelistEndDate
      ) {
        return ProjectStages.whitelist;
      } else if (
        project.status === "not deployed" &&
        Date.now() >= project.whitelistEndDate
      ) {
        return ProjectStages.swapGap;
      } else if (
        (project.status === "upcoming" &&
          Date.now() < project.swapStartDate &&
          !!project.id) ||
        (project.status === "redeem" && Date.now() < project.swapStartDate)
      ) {
        return ProjectStages.swapGapWithResults;
      } else if (
        (project.status === "redeem" &&
          Date.now() >= project.swapStartDate &&
          Date.now() < project.swapEndDate) ||
        (project.status === "fcfs" && Date.now() <= project.swapEndDate)
      ) {
        return ProjectStages.swap;
      } else if (
        project.status === "fcfs" &&
        !leftToRaiseAmount.isZero() &&
        Date.now() > project.swapEndDate &&
        Date.now() < project.fcfsEndDate
      ) {
        return ProjectStages.fcfs;
      } else if (project.status === "fcfs" && Date.now() > project.fcfsEndDate) {
        return ProjectStages.claimGap;
      } else if (project.status === "fcfs" && leftToRaiseAmount.isZero()) {
        return ProjectStages.claimGap;
      } else if (
        project.status === "claim" &&
        (!project.payments?.length || Date.now() < project.payments?.[0].date)
      ) {
        return ProjectStages.claimGap;
      } else if (
        project.status === "claim" &&
        project.payments?.length &&
        Date.now() >= project.payments[0].date
      ) {
        return ProjectStages.claim;
      }
      return ProjectStages.whitelistTba;
    }, [project, leftToRaiseAmount]);
  };
  
  
  export const useOutsideClick = (
    ref: RefObject<HTMLDivElement>,
    action: () => void
  ) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  
  export default function useOnScreen(ref : RefObject<HTMLDivElement | SVGSVGElement | null>, threshold: number = 0.3) {
  
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting), {threshold: threshold}
    )
  
    useEffect(() => {
      if (ref && ref.current)
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }
  export const useClaimCurrencyIconUrl = (
    project?: Ido
  ): string | null => {
    return useMemo(() => {
      if (project) {
        if (project.tokenLogoUrl) return project.tokenLogoUrl;
        if (project.idoLogoUrl) return project.idoLogoUrl;
      }
  
      return null;
    }, [project]);
  };
  
  /**
   * @returns Claim Currency symbol or "TBA"
   */
  export const useClaimCurrencySymbol = (project?: Ido): string => {
    return useMemo(() => {
      if (!project || !project.claimCurrency || !project.claimCurrency.length) {
        return "TBA";
      }
  
      return project.claimCurrency[0].ticket;
    }, [project]);
  };
  
  export const useCurrentExchangeCurrency = (project?: Project) => {
    // const chainId = useSelector(selectChainId);
    const chainId = ChainId.BINANCE_MAINNET
    return useMemo(() => {
      if (!project) return;
  
      let currencies;
      if (isIdo(project)) {
        currencies = project.exchangeCurrency;
      } else {
        currencies = project.exchangeCurrencies;
      }
  
      if (!currencies || !currencies.length) return;
  
      const chainIds = currencies.map(({ chainId }) => chainId);
      //@ts-ignore
      let index = chainIds.findIndex((id) => id === chainId);
  
      if (index < 0) {
        index = chainIds.findIndex((id) => id === project.network);
      }
  
      const currentExchangeCurrency = currencies[index];
      if (!currentExchangeCurrency) return;
  
      return currentExchangeCurrency;
    }, [project]);
  };
  
  /**
   * @returns Exchange Currency or "TBA"
   */
  export const useExchangeCurrencySymbol = (project?: Project): string => {
    const currentExchangeCurrency = useCurrentExchangeCurrency(project);
  
    return useMemo(() => {
      if (!project || !currentExchangeCurrency) return "TBA";
  
      return currentExchangeCurrency.ticket;
    }, [project, currentExchangeCurrency]);
  };
  
  export const useExchangeCurrencyIconUrl = (
    project?: Ido | Ino
  ): string | null => {
    const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);
  
    return useMemo(() => {
      if (!project || exchangeCurrencySymbol === "TBA") return null;
      if (exchangeCurrencySymbol === CurrencyTicket.BUSD) return iBusd;
      return iUsdt;
    }, [project, exchangeCurrencySymbol]);
  };
  
  export const useExchangeCurrencyAddress = (project?: Ido) => {
    const currentExchangeCurrency = useCurrentExchangeCurrency(project);
  
    return useMemo(() => {
      if (!currentExchangeCurrency) return "";
  
      return currentExchangeCurrency.address;
    }, [project, currentExchangeCurrency]);
  };
  
  export const useClaimCurrency = (project?: Ido) => {
    const claimCurrencyIcon = useClaimCurrencyIconUrl(project);
    const claimCurrencySymbol = useClaimCurrencySymbol(project);
  
    return useMemo(
      () => ({ claimCurrencyIcon, claimCurrencySymbol }),
      [claimCurrencyIcon, claimCurrencySymbol, project]
    );
  };
  
  export const useExchangeCurrency = (project?: Ido) => {
    const exchangeCurrencyIcon = useExchangeCurrencyIconUrl(project);
    const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);
    const exchangeCurrencyAddress = useExchangeCurrencyAddress(project);
  
    return useMemo(
      () => ({
        exchangeCurrencyIcon,
        exchangeCurrencySymbol,
        exchangeCurrencyAddress,
      }),
      [
        exchangeCurrencyIcon,
        exchangeCurrencySymbol,
        exchangeCurrencyAddress,
        project,
      ]
    );
  };
  
  export const useCurrency = (project: Ido) => {
    const exchangeCurrency = useExchangeCurrency(project);
    const claimCurrency = useClaimCurrency(project);
  
    return useMemo(
      () => ({ ...exchangeCurrency, ...claimCurrency }),
      [exchangeCurrency, claimCurrency, project]
    );
  };
  
  export const getActivityIcon = (
    activityStatus: ActivityStatus,
    activityType: MarketingActivityType
  ) => {
    if (activityStatus === ActivityStatus.NOW) {
      return ACTIVITIES_ICON_MAP[activityType].active;
    }
    return ACTIVITIES_ICON_MAP[activityType].inactive;
  };
  
  
  export const getProjectImage = (type: ImageType, ownerId?: string): Promise<string> => {
    return new Promise((resolve) => {
      if (!ownerId) resolve("");
  
      const image = new Image();
      image.src = `https://app.gagarin.world/api/images/${ownerId}/${type}`;
      image.onload = () => resolve(image.src);
      image.onerror = () => resolve("");
    });
  };
  
  export const useProjectImage = (type: ImageType, ownerId?: string) => {
    const [url, setUrl] = useState<string>("");
  
    const setImageUrl = async () => {
      const url = await getProjectImage(type, ownerId);
      setUrl(url);
    };
  
    useEffect(() => {
      setImageUrl();
    }, [type, ownerId]);
  
    return url;
  };
  
  
  
  export const getTotalNftBoxesQuantity = (ino?: Ino) => {
    if (!ino || !ino.nftBoxes || !ino.nftBoxes.length) return 0;
  
    return ino.nftBoxes.reduce((result, { totalQuantity }) => {
      result += totalQuantity;
  
      return result;
    }, 0);
  };
  
  export const useTotalNftBoxesQiantity = (ino?: Ino) => {
    return useMemo(() => getTotalNftBoxesQuantity(ino), [ino]);
  };
  
  
  export const useNftBoxMinPrice = (ino: Ino) => {
    return useMemo(() => {
      if (!ino || !ino.nftBoxes || !ino.nftBoxes.length) return 0;
  
      const nftBoxesPrices = ino.nftBoxes.map(({ price }) => {
        return +formatUnits(price);
      });
  
      return Math.min(...nftBoxesPrices);
    }, [ino]);
  };
  
  export const getTotalNftBoxesSoldQuantity = (ino?: Ino) => {
    if (!ino || !ino.nftBoxes || !ino.nftBoxes.length) return 0;
  
    return ino.nftBoxes.reduce((result, { soldQuantity }) => {
      result += soldQuantity;
  
      return result;
    }, 0);
  };
  
  export const useTotalNftBoxesSoldQuantity = (ino?: Ino) => {
    return useMemo(() => getTotalNftBoxesSoldQuantity(ino), [ino]);
  };
  
  export const useTotalNftBoxesLeftQuantity = (ino?: Ino) => {
    const totalNftBoxesSoldQuantity = useTotalNftBoxesSoldQuantity(ino);
    const totalNftBoxesQiantity = useTotalNftBoxesQiantity(ino);
  
    return useMemo(() => {
      if (!ino) return 1;
  
      return totalNftBoxesQiantity - totalNftBoxesSoldQuantity;
    }, [totalNftBoxesQiantity, totalNftBoxesSoldQuantity]);
  };
  
  export const useInoStage = (ino?: Ino): ProjectStage => {
    const totalNftBoxesLeftQuantity = useTotalNftBoxesLeftQuantity(ino);
    // const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
    // const { inoApplication } = useInoApplication(ino);
  
    return useMemo(() => {
      return getInoStage(totalNftBoxesLeftQuantity, ino /* inoApplication */);
    }, [ino, totalNftBoxesLeftQuantity /* inoApplication */]);
  };
  
  
  export const getInoStage = (
    boxesLeftQuantity: number,
    ino?: Ino
    // inoApplication?: InoApplication
  ): ProjectStage => {
    if (!ino) return ProjectStage.pause;
    // if (
    //   isRefund(ino.refundType) ||
    //   (inoApplication && inoApplication.status === ApplicationStatus.REFUND)
    // ) {
    //   return ProjectStage.refund;
    // }
    // if (!ino.whitelistStartDate) {
    //   return ProjectStage.whitelistTba;
    // }
    // if (ino.status === ProjectStatus.NOT_DEPLOYED && Date.now() < ino.whitelistStartDate) {
    //   return ProjectStage.whitelistGap;
    // }
    // if (
    //   ino.status === ProjectStatus.NOT_DEPLOYED &&
    //   Date.now() >= ino.whitelistStartDate &&
    //   Date.now() < ino.whitelistEndDate
    // ) {
    //   return ProjectStage.whitelist;
    // }
    // if (ino.status === ProjectStatus.NOT_DEPLOYED && Date.now() >= ino.whitelistEndDate) {
    //   return ProjectStage.swapGap;
    // }
    // if (
    //   ino.status === ProjectStatus.UPCOMING &&
    //   Date.now() < ino.swapStartDate
    // ) {
    //   return ProjectStage.swapGapWithResults;
    // }
    // if (ino.status === ProjectStatus.REDEEM && Date.now() < ino.swapStartDate) {
    //   return ProjectStage.swapGapWithResults;
    // }
    // if (
    //   ino.status === ProjectStatus.REDEEM &&
    //   Date.now() >= ino.swapStartDate &&
    //   Date.now() < ino.swapEndDate
    // ) {
    //   return ProjectStage.swap;
    // }
    // if (ino.status === ProjectStatus.FCFS && Date.now() <= ino.swapEndDate) {
    //   return ProjectStage.swap;
    // }
    if (
      (ino.status === ProjectStatus.UPCOMING ||
        ino.status === ProjectStatus.NOT_DEPLOYED ||
        ino.status === ProjectStatus.FCFS) &&
      (!ino.fcfsStartDate || Date.now() < ino.fcfsStartDate)
    ) {
      return ProjectStage.fcfsGap;
    }
    if (
      ino.status === ProjectStatus.FCFS &&
      boxesLeftQuantity &&
      Date.now() >= ino.fcfsStartDate &&
      Date.now() < ino.fcfsEndDate
    ) {
      return ProjectStage.fcfs;
    }
    if (ino.status === ProjectStatus.FCFS && Date.now() > ino.fcfsEndDate) {
      return ProjectStage.claimGap;
    }
    if (ino.status === ProjectStatus.FCFS && boxesLeftQuantity <= 0) {
      return ProjectStage.claimGap;
    }
    if (
      ino.status === ProjectStatus.CLAIM &&
      (!ino.claimStartDate || Date.now() < ino.claimStartDate)
    ) {
      return ProjectStage.claimGap;
    }
    // if (rawInvestorInfo && rawInvestorInfo.claimed.toNumber() === ino.payments?.length) {
    //   return ProjectStage.ended;
    // }
    if (ino.status === ProjectStatus.CLAIM && Date.now() >= ino.claimStartDate) {
      return ProjectStage.claim;
    }
    return ProjectStage.pause;
  };
  
  // export const useUpdateInoStage = (ino?: Ino): void => {
  //   const { setProjectStage } = useActions();
  //   const projectStage = useInoStage(ino);
  
  //   useEffect(() => {
  //     setProjectStage(projectStage);
  //   }, [ino, projectStage]);
  // };
  
  
  export const useStageDate = (project: BaseProject, projectFieldName: keyof ProjectDates) => {
    return useMemo(() => {
      if (!project || !projectFieldName || !project[projectFieldName]) return "TBA";
      //@ts-ignore
      return formatDate(project[projectFieldName]);
    }, [project, projectFieldName]);
  };