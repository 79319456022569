import banner from "../../../assets/img/pools/banner-swim.svg";
import token from "../../../assets/img/pools/token-swim.png";
import usdt from "../../../assets/img/tokens/usdt.png";

const OCP = {
  id: "1",
  tokenSymbol: "SWIM",
  TGEDate: 1678395600,
  network: "Ethereum",
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://www.swimspreadwisdom.io/",
      type: "Website",
      projectId: "1",
    },
    {
      id: "7",
      link: "https://t.me/+_RgpFKIL5pE0MWVl",
      type: "Telegram",
    },
    // {
    //   id: "6",
    //   link: "http://t.me/totkitbot",
    //   type: "Telegram",
    // },
    {
      id: "2",
      link: "https://twitter.com/SwimSpreadWis?t=X4NoevK_p_qOUYrXoDqJ6w&s=08",
      type: "Twitter",
      projectId: "1",
    },
    {
      id: "5",
      link: "https://discord.gg/G24Vcs9b5P",
      type: "Discord",
      projectId: "1",
    },
    {
      id: "0",
      type: "Instagram",
      link: "https://instagram.com/swimspreadwisdom/"
    },
  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1",
    },
    {
      id: "2",
      path: '',
      type: "logo",
      projectId: "1",
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1",
    },
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "Swim - Spread Wisdom",
      projectId: "1",
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: `SWIM - Spread Wisdom
      GAMING-LEARNING -EARNING
      The World's 1st Wisdom Utility Platform That Offers Rewards!! "WISE-ME '' our First-Ever Toddlers/ Parents NFT Game and (MIAMI) METAVERSE INSIDE METAVERSE INCEPTION. Our Goal: - To create a future generation of wiser, environmentally conscious entrepreneurs through the 'WISEME' SWIM Toddlers gaming app alongside other futuristic innovations'' Our Aim:- Incubating via gaming app ideas that help toddlers flow towards attaining well-being and wisdom about nature, with daily wisdom task rewards in the form of ZooNFT on a decentralized platform." As parents, we understand them task le of [HE 3X PRICE RISi balancing SCREEN TIME for children. That's why we came up with a solution - parents can save rewards as toddlers complete tasks. This turns screen time into a wise use of time for children and offers incentives for parents to encourage their children to engage in meaningful activities. But that's not all! We're also introducing the first-ever kids NT to digital gold conversion on the safest blockchain network protocols. This allows for wise security for parents who want to secure their
      TO INVEST?
      children's future. At the same time, we're also passionate. about nurturing the environment. That's why we're providing extra rewards for parents who spend time with their children in nature and inspiring them to use recyclable, environmentally friendly products for kids. We want to encourage a
      YOU WILL RECEIVE
      sense of responsibility and care for the environment in our children and reward families who do the same. NOw- Unlock the power of wisdom for a healthier Earth and humanity with "WISE ME," the revolutionary NFT Play-To-Earn game for toddlers and parents that combines Gaming-Earning-Learning.
      `,
      projectId: "1",
    },
    // {
    //   id: "3",
    //   type: "description",
    //   locale: "ru",
    //   value: `OCP токен это глобальный проект, задачей которого является построение глобальной экосистемы по принципу: «Единый мир, объединенный через знания и обмен». OCP создает систему вознаграждений, где каждый человек, который использует и поддерживает OCP, получает награду. Это могут быть токены, ваучеры от партнерских отраслей, скидки и даже участие в прибыли отдельных проектов. OCP станет будущим платежей и основой для преодоления безработицы, поддержки образования и инновационного бизнеса по всему миру.
    //   Материнской компание OCP  является некоммерческая организация OTI которая имеет 16 офисов по всему миру, включая Соединенное Королевство и Кипр. С 2012 года OTI помогла получить образование, освоить новую профессию и получить новые возможности более 5 млн человек.`,
    //   projectId: "1",
    // },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "2 month cliff from the date of purchase, vested over 9 months",
      projectId: "1",
    },
    // {
    //   id: "5",
    //   type: "vesting",
    //   locale: "ru",
    //   value: "2 месяца клифф после даты покупки, vested over 9 months",
    //   projectId: "1",
    // },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://www.swimspreadwisdom.io/about-3",
      projectId: "1",
    },
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "usdt",
      icon: usdt,
    },
  ],
};

export default OCP;
