import { Trans, useTranslation } from "react-i18next";
import tgIcon from "../../assets/img/tg.svg";
import Rays from "../components/rays";
import { LINKS } from "../data/links";
import "./hero.section.sass";

const HeroSection = () => {
  const {t, i18n} = useTranslation()
  const tgHref = i18n.language == "ru" ? LINKS.telegram.ru : LINKS.telegram.en
  return (
    <section className="hero">
      <h1 className="hero__title title">
      <Trans i18nKey={"hero.title"}>
        <span className="dark">GAGARIN Fundraising Portal</span> <br />
        Discover the best early-stage <br /> project token sales
      </Trans>
      </h1>
      <p className="sub hero__sub">
        <Trans i18nKey={'hero.desc'}>
        Become an early investor in the most innovative projects in Web3. Buy
        project tokens <br />
        at an early stage at the best price directly on project websites

        </Trans>
      </p>
      <div className="hero__numbers">
        <div className="hero__number">
            <p className="hero__num-sub desc">{t("Gagarin Total raise")}</p>
            <div className="hero__num gradi">$1,2M</div>
        </div>
        <div className="hero__number">
            <p className="hero__num-sub desc">{t("Number of investors")}</p>
            <div className="hero__num gradi">2000+</div>
        </div>
        <div className="hero__number">
            <p className="hero__num-sub desc">{t("Pools")}</p>
            <div className="hero__num gradi">7</div>
        </div>
      </div>
      <a href={LINKS.tgBot} target="_blank" rel="noreferrer" className="hero__tg">
        <p>Gagarin</p>
        <p>telegram chat</p>
    <img src={tgIcon} alt="" className="hero__tg-icon" />
      </a>
      <Rays />
    </section>
  );
};

export default HeroSection;
