import { useEffect, useRef, useState } from "react"
import { ReactComponent as RaysImage } from "../../assets/img/rays.svg"
import useOnScreen from "../hooks"
import './rays.sass'

type Props = {
    defaultAppear?: boolean
}

const Rays = ({defaultAppear}: Props) => {
    const [active, setActive] = useState(false)
    const raysRef = useRef<SVGSVGElement | null>(null)
    const isVisible = useOnScreen(raysRef, 0.3)
    useEffect(()=>{
        defaultAppear && setActive(true) 
    })
    useEffect(()=>{
        if (isVisible && !active){
            setTimeout(()=>{
                setActive(true)
            }, 100)
        }
    }, [isVisible, active])
    return (
            <RaysImage ref={raysRef} className={`rays ${active ? 'active' : ''}`}/>

    )
}

export default Rays