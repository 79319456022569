import ActivitiesSection from "../sections/activities.section"
import BottomSection from "../sections/bottom.section"
import FAQSection from "../sections/faq.section"
import HeroSection from "../sections/hero.section"
import PoolsSection from "../sections/pools.section"
import ProjectsSection from "../sections/projects.section"

const MainPage = () => {
    return <div className="main-page">
        <HeroSection />
        <PoolsSection />
        <ProjectsSection />
        <ActivitiesSection />
        <FAQSection />
        <BottomSection />
    </div>
}

export default MainPage