/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  VoucherV2,
  VoucherV2Interface,
} from "../../../contracts/meta-transactions/VoucherV2";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    inputs: [],
    name: "getChainID",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5060d78061001f6000396000f3fe6080604052348015600f57600080fd5b506004361060325760003560e01c8063564b81ef1460375780637ecebe0014604b575b600080fd5b465b60405190815260200160405180910390f35b603960563660046068565b60356020526000908152604090205481565b6000602082840312156078578081fd5b813573ffffffffffffffffffffffffffffffffffffffff81168114609a578182fd5b939250505056fea264697066735822122077883487d2daf9ebb8429041aa954def9f0f5d3e35c50d548f1ae26265bf39db64736f6c63430008040033";

type VoucherV2ConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: VoucherV2ConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class VoucherV2__factory extends ContractFactory {
  constructor(...args: VoucherV2ConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<VoucherV2> {
    return super.deploy(overrides || {}) as Promise<VoucherV2>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): VoucherV2 {
    return super.attach(address) as VoucherV2;
  }
  override connect(signer: Signer): VoucherV2__factory {
    return super.connect(signer) as VoucherV2__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): VoucherV2Interface {
    return new utils.Interface(_abi) as VoucherV2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VoucherV2 {
    return new Contract(address, _abi, signerOrProvider) as VoucherV2;
  }
}
