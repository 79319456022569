import { Trans, useTranslation } from "react-i18next";
import { useIdosQuery } from "../../redux/projects.api";
import RocketLoader from "../components/loader";
import Project from "../components/project.item";
import "./projects.section.sass";

const ProjectsSection = () => {
  const { data: data, isFetching } = useIdosQuery();
  const { t } = useTranslation();
  return (
    <section className="projects">
      <h3 className="projects__title title">
        <Trans i18nKey={"projects.title"}>
          Go to our launchpad, participate <br />
          in major projects and earn!
        </Trans>
      </h3>
      <div className="projects__box">
        {isFetching ? (
          <RocketLoader className="projects__loader" />
        ) : (
          <div className="projects__list">
            {data?.map((el, i) => (i < 3 ? <Project project={el} /> : null))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ProjectsSection;
