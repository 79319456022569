import { Trans, useTranslation } from "react-i18next";
import tgIcon from "../../assets/img/tg.svg";
import { TPoolInfo } from "../../redux/gft.types";
import Pool from "../components/pool.item";
import Dellcoin from "../components/temp/dellcoin";
import HUNGERCHAINS from "../components/temp/hunger-chains";
import OCP from "../components/temp/ocp";
import SWIM from "../components/temp/swim";
import TOTKIT from "../components/temp/totkit";
import WESLEEP from "../components/temp/wesleep";
import { LINKS } from "../data/links";
import "./pools.section.sass";

const PoolsSection = () => {
  const {t, i18n} = useTranslation()

  return (
    <section className="pools">
      <a href={LINKS.tgBot} target="_blank" rel="noreferrer" className="pools__tg">
        <p className="pools__tg-sub">
          <Trans i18nKey={"tg.questions"}>
          Do you have a question? <br />
          Ask it in our chat!
          </Trans>

        </p>
        <img src={tgIcon} alt="" className="pools__tg-icon" />
      </a>
      <p className="desc pools__desc">{t("pools.title")}</p>
      <div className="pools__items">
          {/* <Pool index={8} pool={QTKX as TPoolInfo} /> */}
          {/* <Pool index={7} pool={GAGARIN as TPoolInfo} /> */}
          {/* <Pool index={6} pool={SHELTERZ as TPoolInfo} /> */}
          <Pool index={5} pool={Dellcoin as TPoolInfo} />
          <Pool index={4} pool={WESLEEP as TPoolInfo} />
          <Pool index={3} pool={OCP as TPoolInfo} />
          {/* <Pool index={2} pool={NFTPAINTINGS as TPoolInfo} /> */}
          <Pool index={1} pool={HUNGERCHAINS as TPoolInfo} />
          <Pool index={0} pool={TOTKIT as TPoolInfo} />
          <Pool index={0} pool={SWIM as TPoolInfo} />
        </div>

    </section>
  );
};

export default PoolsSection;
