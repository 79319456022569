import { ReactComponent as RocketIcon } from "../../assets/img/rocket-loader.svg";
import './loader.sass';

interface Props {
    className?: string
}

const RocketLoader = ({className} : Props) => {
    return <div className={`rocket-loader ${className ? className : ""}`}>
        <div className="rocket-loader__inner">
            <RocketIcon className="rocket-loader__icon"/>
        </div>
    </div>
}

export default RocketLoader
