import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainPage from "./main.page";
type TProps = {};

function Pages({}: TProps) {
  return (
    <Switch>
      <Route path="/">
        <MainPage />
      </Route>
    </Switch>
  );
}

export default Pages;
