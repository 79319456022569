import './input.sass'
import {ReactComponent as MailIcon} from '../../assets/img/mail.svg'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    className?: string,
    placeholder? : string,
    onChange? : any
    error? : string
}

const EmailInput = forwardRef<HTMLInputElement, Props>(({onChange, error}, ref) => {
    const {t} = useTranslation()
    const [focused, setFocused] = useState(false)
    return <div className={`input-container ${error ? "err" : ''}`}>
        <input ref={ref} onChange={onChange} onFocus={()=>setFocused(true)} onBlur={()=>setFocused(false)} type="text" className={`input ${error ? "err" : ''}`} placeholder='youremail@gmail.com' />
        <MailIcon className={`input-container-img ${focused ? "focused" : ''} ${error ? "error" : ''}`} />
        {error && (
            <div className="input__error">{t(error)}</div>
        )}
    </div>
})

export default EmailInput