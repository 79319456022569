import { useTranslation } from "react-i18next";
import discord from "../../assets/img/socials/discord.svg";
import linkedin from "../../assets/img/socials/in.svg";
import medium from "../../assets/img/socials/medium.svg";
import telegram from "../../assets/img/socials/telegram.svg";
import twitter from "../../assets/img/socials/twitter.svg";
import { LINKS } from "../data/links";
import "./footer.sass";


type TProps = {};

function Footer({}: TProps) {
  const {t} = useTranslation()
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__socials">
        <p className="footer__title footer__sub">{t("footer.socials")}</p>
        <div className="footer__socials-box">
          <a href={LINKS.socials.twitter} target="_blank" rel="noreferrer" className="footer__social">
            <img src={twitter} alt="" className="footer__social-icon" />
          </a>
          <a href={LINKS.socials.discord} target="_blank" rel="noreferrer" className="footer__social">
            <img src={discord} alt="" className="footer__social-icon" />
          </a>
          <a href={LINKS.socials.medium} target="_blank" rel="noreferrer" className="footer__social">
            <img src={medium} alt="" className="footer__social-icon" />
          </a>
          <a href={LINKS.socials.linkedIn} target="_blank" rel="noreferrer" className="footer__social">
            <img src={linkedin} alt="" className="footer__social-icon" />
          </a>
          <a href={LINKS.telegram.ru} target="_blank" rel="noreferrer" className="footer__social">
            <img src={telegram} alt="" className="footer__social-icon" />
            <p className="footer__social-sub">Ру</p>
          </a>
          <a href={LINKS.telegram.en} target="_blank" rel="noreferrer" className="footer__social">
            <img src={telegram} alt="" className="footer__social-icon" />
            <p className="footer__social-sub">En</p>
          </a>
          <a href={LINKS.telegram.tur} target="_blank" rel="noreferrer" className="footer__social">
            <img src={telegram} alt="" className="footer__social-icon" />
            <p className="footer__social-sub">Tur</p>
          </a>
          <a href={LINKS.telegram.cn} target="_blank" rel="noreferrer" className="footer__social">
            <img src={telegram} alt="" className="footer__social-icon" />
            <p className="footer__social-sub">Cn</p>
          </a>
        </div>
        </div>
        <div className="footer__items">
        <a href={LINKS.applyIdo} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.ido")}</a>
        <a href={LINKS.applyIno} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.ino")}</a>
        <a href={LINKS.applyIncubation} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.incubation")}</a>

        </div>
        <div className="footer__items">
          <a href={LINKS.privacy} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.privacy")}</a>
          <a href={LINKS.service} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.service")}</a>

        </div>





        <div className="footer__items">
        <a href={LINKS.documentation} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.docs")}</a>
        <a href="mailto:yuri@gagarin.world" target="_blank" rel="noreferrer" className="footer__sub">yuri@gagarin.world</a>


        </div>
        <div className="footer__items">
          <a href={LINKS.taplink} target="_blank" rel="noreferrer" className="footer__sub">{t("footer.allLinks")}</a>

        </div>
      </div>
    </div>
  );
}

export default Footer;
