import './button.sass'


type Props = {
    onClick? : any,
    children: any,
    className?: string,
    disabled? : boolean
    type? : "submit"
}

const Button = ({children, className, onClick, disabled, type} : Props) => {
    return <button type={type} className={`button ${className ? className : ''} ${disabled ? '_disabled' : ''}`} onClick={onClick}>
        {children}
    </button>
}

export default Button