import { TProjectActivity, Translation } from "../../redux/gft.types";
import { useGetTranslations } from "../hooks";
import "./activity.item.sass";

type Props = {
  item: TProjectActivity;
};

type TFilteredTranslation = {
    en?: string,
    ru?: string
}




const ActivityItem = ({ item }: Props) => {

    const title = useGetTranslations(Translation.TITLE, item.translations || [])
    const desc = useGetTranslations(Translation.DESCRIPTION, item.translations || [])
    const banner = item.images?.length && item.images[0].path
    const link = useGetTranslations(Translation.LINK, item.translations || [])
  return (
    <a href={link} className="activity">
      <div className="activity__frame" style={{
        backgroundImage: `url(${banner})`
      }}></div>
      <div className="activity__content">
        <div className="activity__title">{title}</div>
        <div className="activity__desc">{desc}</div>
      </div>
    </a>
  );
};

export default ActivityItem
