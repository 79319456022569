import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { TProjectActivity } from "../../redux/gft.types";
import { useActivitiesQuery } from "../../redux/pools.api";
import ActivityItem from "../components/activity.item";
import ACTIVITIES from "../components/temp/activities";
import "./activities.section.sass";
const ActivitiesSection = () => {
  const { data: activities, isFetching } = useActivitiesQuery();
  const { t } = useTranslation();
  return (
    <section className="activities">
      <h3 className="activities__title title">{t("activities.title")}</h3>
      {/* {isFetching ? (
        <RocketLoader className="activities__loader" />
      ) : ( */}
        <Swiper slidesPerView={"auto"} modules={[Pagination, Navigation]} pagination navigation breakpoints={{
          1425: {
            slidesPerView: 3
          },
        }}>
          {ACTIVITIES.map((el) => {
            return (
              <SwiperSlide>
                <ActivityItem item={el as TProjectActivity} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      {/* )} */}
    </section>
  );
};

export default ActivitiesSection;
