import { useTranslation } from "react-i18next";
import { LINKS } from "../data/links";
import Button from "./button";
import Dropdown from "./dropdown";
import "./header.menu.sass";
import Soon from "./soon.icon";

type Props = {
  visible?: boolean;
  _ref?: any;
  handler: any
};

const HeaderMenu = ({ visible, _ref, handler }: Props) => {
  const goTo = (selector: string) => {
    handler(false);
    document.querySelector(selector)?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const {t} = useTranslation()
  return (
    <div ref={_ref} className={`header-menu ${visible ? "_visible" : ""}`}>
      <div className="header-menu__items">
        <a href={LINKS.servicesLanding}
            target={"_blank"}
            rel="noreferrer" className="header-menu__item">{t("header.projects")}</a>
        <a href={LINKS.landing} target={"_blank"}
            rel="noreferrer" className="header-menu__item">Gagarin.world</a>
        <a onClick={()=>goTo('.faq')} className="header-menu__item">{t("header.about")}</a>
        <a href={LINKS.launchpad} target={"_blank"}
            rel="noreferrer" className="header-menu__item">{t("header.launchpad")}</a>
        
        
        <a href={LINKS.submitYourProject} target={"_blank"}
            rel="noreferrer" className="header-menu__item">{t("header.submit")}</a>
        <Button onClick={()=>{
          window.open(LINKS.submitYourProject)
        }} className="header-menu__button">{t("header.apply")}</Button>
      </div>
    </div>
  );
};

export default HeaderMenu;
