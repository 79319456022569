export enum Language {
    EN = "en",
    RU = "ru",
  }
  export enum ProjectStatusTag {
    UPCOMING = "upcoming",
    LIVE = "live",
    CLOSED = "closed",
    REFUND = "refund",
    REFUNDED = "refunded",
    EXPANDED = 'expanded',
    POSTPONED = 'postponed'
  }
  
  
  export enum ProjectStage {
    whitelistTba = 1, // дата whitelist неизвестна
    whitelistGap, // дата whitelist известна, но не наступила
    whitelist, // whitelist открыт
    swapGap, // whitelist закрыт, но лотерея не проведена
    swapGapWithResults, // whitelist закрыт, лотерея проведена, но дата swap не наступила
    swap, // swap начался
    fcfsGap, // swap закончился, fcfs не начался
    fcfs, // fcfs начался
    claimGap, // fcfs закончился, дата claim не наступила
    claim, // claim начался
    ended,
    refund, // возврат средств
    refundPolicy, // ручной возврат средств после TGE
    pause,
  }
  
  
  export enum NetworkName {
    UNSET = "TBA",
    BINANCE_MAINNET = "BNB Chain",
    BINANCE_TESTNET = "BNB Chain Testnet",
    COINEX_MAINNET = "CSC",
    COINEX_TESTNET = "CSC Testnet",
    OKC_MAINNET = "OKC",
    OKC_TESTNET = "OKC Testnet",
    POLYGON_MAINNET = "Polygon",
    POLYGON_TESTNET = "Polygon Testnet",
  }
  export enum ChainId {
    UNSET = 0,
    BINANCE_MAINNET = 56,
    BINANCE_TESTNET = 97,
    COINEX_MAINNET = 52,
    COINEX_TESTNET = 53,
    OKC_MAINNET = 66,
    OKC_TESTNET = 65,
    POLYGON_MAINNET = 137,
    POLYGON_TESTNET = 80001,
  }
  export enum RefundType {
    NO_REFUND = "noRefund",
    WITH_PROJECT_TOKENS = "withProjectTokens",
    WITHOUT_PROJECT_TOKENS = "withoutProjectTokens",
    REFUND_POLICY_WITH_CLAIM = "refundPolicyWithClaim",
    REFUND_POLICY_WITHOUT_CLAIM = "refundPolicyWithoutClaim",
    REFUNDED = "refunded",
  }
  
  export enum CurrencyTicket {
    BUSD = "BUSD",
    USDT = "USDT",
  }
  
  export enum ActivityStatus {
    NOW,
    NOT_STARTED,
    ENDED,
  }
  
  export enum QueryParam {
    MARKETING_ACTIVITY_TYPE = "activity",
  }