import banner from "../../../assets/img/pools/banner-ocp.svg";
import logo from "../../../assets/img/pools/logo-ocp.png";
import token from "../../../assets/img/pools/token-ocp.png";
import usdt from "../../../assets/img/tokens/usdt.png";

const OCP = {
  id: "1",
  tokenSymbol: "OCP",
  network: "BNB Chain",
  TGEDate: 'ATP',
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://ocptoken.org ",
      type: "Website",
      projectId: "1",
    },
    {
      id: "7",
      link: "https://t.me/otigrouporg",
      type: "Telegram",
    },
    {
      id: "2",
      link: "https://twitter.com/otigrouporg",
      type: "Twitter",
      projectId: "1",
    },

    {
      id: "5",
      link: "https://discord.gg/7BCduZ9X",
      type: "Discord",
      projectId: "1",
    },


  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1",
    },
    {
      id: "2",
      path: logo,
      type: "logo",
      projectId: "1",
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1",
    },
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "OTI Community Project Token",
      projectId: "1",
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: `OCP token is a global project, whose task is to build a global ecosystem according to the principle: "One world, united through knowledge and exchange." OCP creates a reward system where every person who uses and supports OCP is rewarded. These can be tokens, vouchers from partner industries, discounts, and even participation in the profits of individual projects. OCP will be the future of payments and the foundation for overcoming unemployment, supporting education and innovative businesses around the world.
      OCP's parent company is the non-profit organization OTI which has 16 offices around the world, including the United Kingdom and Cyprus. Since 2012, OTI has helped more than 5 million people get an education, a new profession and new opportunities.
      `,
      projectId: "1",
    },
    {
      id: "3",
      type: "description",
      locale: "ru",
      value: `OCP токен это глобальный проект, задачей которого является построение глобальной экосистемы по принципу: «Единый мир, объединенный через знания и обмен». OCP создает систему вознаграждений, где каждый человек, который использует и поддерживает OCP, получает награду. Это могут быть токены, ваучеры от партнерских отраслей, скидки и даже участие в прибыли отдельных проектов. OCP станет будущим платежей и основой для преодоления безработицы, поддержки образования и инновационного бизнеса по всему миру.
      Материнской компание OCP  является некоммерческая организация OTI которая имеет 16 офисов по всему миру, включая Соединенное Королевство и Кипр. С 2012 года OTI помогла получить образование, освоить новую профессию и получить новые возможности более 5 млн человек.`,
      projectId: "1",
    },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "20% on TGE, remaining over 6 months",
      projectId: "1",
    },
    {
      id: "5",
      type: "vesting",
      locale: "ru",
      value: "20% на TGE, остальное через 6 месяцев",
      projectId: "1",
    },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://fundraising.gagarin.world/form/63b68932c5d54e08cb0575a5 ",
      projectId: "1",
    },
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "ustd",
      icon: usdt,
    },
  ],
};

export default OCP;
