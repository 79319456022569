import Button from "../components/button";
import "./bottom.section.sass";
import download from "../../assets/img/download.svg";
import back from "../../assets/img/back.png";
import EmailInput from "../components/input";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import axios from "axios";
import { LINKS } from "../data/links";

const BottomSection = () => {
  const source = window.location.hostname;
  const [mail, setMail] = useState("");
  const [errmsg, setErrmsg] = useState("");
  const inputRef = useRef<null | HTMLInputElement>(null);
  const { t } = useTranslation();

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (mail) {
        setErrmsg('')
      try {
        axios
          .post(`${process.env.REACT_APP_PROJECTS_API}/client-emails`, {
            email: mail,
            source: source,
          })
          .catch((err) => setErrmsg("Enter the correct address"));
      } catch (e) {
       
      }
      if (inputRef.current) {
        setMail('')
        inputRef.current.value = "";
      }
    }
  };

  return (
    <section className="bottom">
      <div className="bottom__box">
        <div className="bottom__item">
          <div className="bottom__title">{t("bottom.title1")}</div>
          <div className="bottom__buttons">
            <Button onClick={() => {window.open(LINKS.submitYourProject)}}>{t("bottom.apply")}</Button>
            <a className="bottom__download" href={LINKS.presentation2} target="_blank" rel="noreferrer">
              <img src={download} alt="" className="bottom__download-icon" />
              {t("bottom.download")}
            </a>
          </div>
        </div>
        <div className="bottom__item">
          <div className="bottom__title">{t("bottom.title2")}</div>
          <form onSubmit={(e) => onSubmit(e)} className="bottom__buttons">
            <EmailInput
              ref={inputRef}
              error={errmsg}
              onChange={(e: any) => setMail(e.target.value)}
            />
            <Button type="submit" onClick={() => {}}>{t("bottom.mail")}</Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default BottomSection;
