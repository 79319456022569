export const ru = {
  "Enter the correct address" : "Введите корректный адрес",

  "Tokenomics": "Токеномика",
  "Whitepaper": "Документация",
  "Pitchdeck": "Презентация",
  poolDisclaimer: "Представленная информация не является инвестиционной рекомендацией. Платформа GAGARIN не собирает и не управляет средствами участников.",

  "website": "вебсайт",
  "participate" : "участвовать",
  "Vesting" : "Вестинг",

  "Learn more" : "Узнать больше",
  "Whitelist start": "Открытие Вайтлиста",
  "Whitelist end": "Закрытие Вайтлиста",
  "Swap start": "Начало Выкупа",
  "Swap end": "Конец Выкупа",
  "FCFS end": "Конец FCFS",
  "Next claim": "Следующая Выплата",
  "Payments are over": "Выплаты окончены",
  "Whitelist starts on": "Вайтлист открывается",
  "Whitelist ends on": "Вайтлист закрывается",
  "Swap starts on": "Выкуп начинается",
  "Swap ends on": "Выкуп заканчивается",
  "FCFS ends on": "FCFS заканчивается",
  "Whitelist starts after": "Вайтлист открывается через",
  "Whitelist ends after": "Вайтлист закрывается через",
  "Swap starts after": "Выкуп начинается через",
  "Swap ends after": "Выкуп заканчивается через",
  "FCFS ends after": "FCFS заканчивается через",
  "Next claim at": "Следующая Выплата",
  "Exchange Rate": "Курс Обмена",
  "Total Raise": "Сумма сбора",
  "Box Count": "Количество лутбоксов",
  "One Lootbox": "Цена одного лутбокса",
  "Gagarin Total raise": "общая сумма сбора",
  from: "от",
  "IDO Launchpad": "IDO лаунчпад",
  "INO Launchpad": "INO лаунчпад",
  Refund: "Возврат",
  Refunded: "Возвращено",
  Pools: "Клиенты GFT",
  "Number of investors": "количество участников",
  "Total raise": "Сумма сбора",
  invest: "Участвовать",
  tg: {
    questions: "У вас есть вопрос? <1></1> Задайте его в нашем чате!",
  },
  header: {
    projects: "Для проектов",
    "Gagarin.world": "Gagarin.world",
    about: "О нас",
    launchpad: "Лаунчпад",
    submit: "Разместить свой проект",
    apply: "Подать заявку",
  },
  hero: {
    title:
      "<0> GAGARIN Fundraising portal </0> <2></2>Узнай о лучших проектах <2></2> на ранних стадиях продаж",
    desc: `Станьте ранним инвестором в самых инновационных Web3 проектах. <1></1>Покупайте токены на ранней стадии продаж по лучшей цене прямо на официальных сайтах проектов.`,
  },
  pools: {
    title: "Пулы",
    disclaimer:
      "Отказ от ответственности:  <1>*</1> Представленная информация не является рекомендацией относительно того, следует ли вам инвестировать в какой-либо обсуждаемый продукт. Платформа GAGARIN не собирает и не управляет средствами инвесторов. Все средства, которые вы инвестируете в пул по сбору средств, переводятся непосредственно в проект",
  },
  projects: {
    title:
      "Заходи на наш лаунчпад, инвестируй <1></1> в перспективные проекты и зарабатывай!",
  },
  activities: {
    title: "Участвуй и выигрывай!",
  },
  faq: {
    title: "Часто задаваемые вопросы",
    _1: {
      title: "Что такое GAGARIN Fundraising Portal?",
      desc: "Это площадка, предназначенная для прямого привлечения инвестиций в стартапы. Сбор осуществляется с помощью GAGARIN Fundrasing Tool — специального инструмента от экосистемы GAGARIN. Он представляет собой форму, которая встраивается на сайт проекта и запускает сборы на площадке. После этого инвестору достаточно зайти на Fundrasing Portal, выбрать понравившиеся проект, подключить кошелек и перевести средства.",
    },
    _2: {
      title: "Какие преимущества у GAGARIN Fundraising Portal?",
      desc: `— Командам стартапов Fundraising Portal предлагает доступный функционал для сбора финансирования на реализацию и развитие своих идей, а также сформированное сообщество потенциальных инвесторов. <0></0> <0></0>
      <0></0>Преимущества для инвесторов: <0></0>
      — инвестор получает на одной платформе доступ к ранним продажам криптостартапов. <0></0>
      — существенно увеличивает диапазон выбора стартапов, на которых можно заработать.

            `,
    },
    _3: {
      title:
        "В чем разница между GAGARIN Fundraising Portal и GAGARIN launchpad?",
      desc: `— Обе площадки помогают стратапам в привлечении инвестиций для развития, но отличаются схемой перечисления средств. В случае с лаунчпадом они сначала аккумулируются на его счете, поэтому GAGARIN может контролировать поступление и распределение активов. Инвестиции же, привлеченные с Fundraising Portal, напрямую поступают в распоряжение проектов. <0></0> С этой особенностью связано еще одно отличие — отсутствие на портале механизма защиты инвесторов, который действует на лаунчпаде.
            `,
    },
    _4: {
      title: "Где я могу прочитать об этом подробнее?",
      desc: `— Больше информации о проекте Fundraising Portal можно найти в <1>АКАДЕМИИ GAGARIN на gitbook</1>.`,
    },
    _5: {
      title: "Насколько безопасно участвовать в проектах через GAGARIN Fundraising portal?",
      desc: `Все проекты, представленные на портале, используют Gagarin Fundraising Tool. Представленная информация не является инвестиционной рекомендацией. Платформа GAGARIN не собирает и не управляет средствами участников. Проекты - владельцы пулов организуют сбор средств на своих сайтах.`,
    },

  },
  bottom: {
    title1: "Разместите свой проект на GAGARIN Fundraising pool",
    title2: "Получайте  новости о новых проектах по электронной почте",
    download: "Скачать презентацию",
    apply: "Подать заявку сейчас",
    mail: "Получать",
  },
  footer: {
    title: "Присоединяйтесь к нашему сообществу",
    socials: "Присоединяйтесь к нам",
    community: {
      title: "Стань частью нашего комьюнити",
      investor: "Стать инвестором",
      expert: "Стать экспертом",
      partner: "Стать партнёром",
      ambassador: "Стать амбассадором",

    },
    ido: "Заявка на IDO",
    ino: "Заявка на INO",
    privacy: "Политика конфиденциальности",
    service: "Условия обслуживания",
    docs: "Документация",
    whitepaper: "Whitepaper",
    tokenomics: "Токеномика",
    incubation: "Заявка на Инкубацию",
    allLinks: "Ссылки на все ресурсы",
    referral: "Заявка на Глобальную <1></1> реферальную программу",
    announces: "Каналы анонсов"
  },
};
