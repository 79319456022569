import banner from '../../../assets/img/pools/banner-dell.png'
import logo from '../../../assets/img/pools/logo-dell.png'
import token from '../../../assets/img/pools/token-dell.png'
import busd from '../../../assets/img/tokens/busd.svg'
import usdc from '../../../assets/img/tokens/usdc.png'
import usdt from '../../../assets/img/tokens/usdt.png'


const Dellcoin = {
  id: "1",
  tokenSymbol: "$DELL",
  network: "BNB Chain",
  TGEDate: 1672606800,
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://dellcoin.org",
      type: "Website",
      projectId: "1"
    },
    {
      id: "2",
      link: "https://t.me/Dellcoin1",
      type: "Telegram",
      projectId: "1"
    },
    {
      id: "2",
      link: "https://t.me/Dellcoin2",
      type: "Telegram",
      projectId: "1"
    },
    {
      id: "3",
      link: "https://twitter.com/dellcoin22",
      type: "Twitter",
      projectId: "1"
    },
    {
      id: "5",
      link: "https://docsend.com/view/vcii27drkamjj7by",
      type: "Tokenomics",
      projectId: "1"
    },
    {
      id: "6",
      link: "https://docsend.com/view/2nrj4txqu3jjv5aa",
      type: "Whitepaper",
      projectId: "1"
    },
    {
      id: "7",
      link: "https://bscscan.com/token/0x8b6f016dcbe9832e772519b503c019ea4bffb43c",
      type: "Bscscan"
    }
  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1"
    },
    {
      id: "2",
      path: logo,
      type: "logo",
      projectId: "1"
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1"
    }
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "DELL COIN",
      projectId: "1"
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: "Dell NFT platform is a social marketplace where social media posts and videos are created in NFTs & traded against any crypto.",
      projectId: "1"
    },
    {
      id: "3",
      type: "description",
      locale: "ru",
      value: "Платформа Dell NFT — это социальная торговая площадка, на которой посты и видеоролики в социальных сетях создаются в NFT и торгуются с любой криптовалютой.",
      projectId: "1"
    },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "10% at TGE and 7.5% monthly",
      projectId: "1"
    },
    {
      id: "5",
      type: "vesting",
      locale: "ru",
      value: "10% на TGE и далее по 7,5% ежемесячно",
      projectId: "1"
    },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://dellcoin.org/",
      projectId: "1"
    }
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "ustd",
      icon: usdt
    },
    {
      id: "2",
      name: "busd",
      icon: busd
    },
    {
      id: "3",
      name: "usdc",
      icon: usdc
    }
  ]
}

export default Dellcoin
