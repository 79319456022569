import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useTranslation } from "react-i18next";
import { Ido, ImageType } from "../../sdk";
import { ProjectStages } from "../types";
import "./project.item.sass";
import {
  formatBigNumber,
  formatDate,
  PAYMENTS_OVER,
  useCurrency,
  useNextClaimByProject,
  useProjectFcfsEnd,
  useProjectFill,
  useProjectImage,
  useProjectInvestedPercent,
  useProjectStage,
  useProjectSwapEnd,
  useProjectSwapStart,
  useProjectTotalSwap,
  useProjectWhitelistEnd,
  useProjectWhitelistStart
} from "./projects.hooks";

const formatExchangeRate = (rate: string): string => {
  if (BigNumber.from(rate || "0").isZero()) {
    return "TBA";
  }
  const result = formatEther(BigNumber.from(rate) || "0");

  return result;
};

type Props = {
  project: Ido;
};

const Project = ({ project }: Props) => {
  const { exchangeCurrencyIcon } = useCurrency(project);
  const projectBanner = useProjectImage(ImageType.BANNER, project.id);
  const projectToken = useProjectImage(ImageType.TOKEN_LOGO, project.id);
  const exchangeRate = +formatExchangeRate(project.exchangeRate)
    ? `1 ${
        (project.claimCurrency && project.claimCurrency[0].ticket) || "TBA"
      } = ${formatExchangeRate(project.exchangeRate)} ${
        (project.exchangeCurrency && project.exchangeCurrency[0].ticket) ||
        "TBA"
      }`
    : "TBA";
  const totalRaise = +formatBigNumber(project.totalRaise).replaceAll(" ", "")
    ? `$${formatBigNumber(project.totalRaise)}`
    : "TBA";

  const { t } = useTranslation();

  let projectStatus = "TBA";
  const percent = useProjectInvestedPercent(project);
  const filled = useProjectFill(project);
  const swap = useProjectTotalSwap(project);
  const projectStage = useProjectStage(project);
  const projectWhitelistStart = useProjectWhitelistStart(project);
  const projectWhitelistEnd = useProjectWhitelistEnd(project);
  const projectSwapStart = useProjectSwapStart(project);
  const projectSwapEnd = useProjectSwapEnd(project);
  const projectFcfsEnd = useProjectFcfsEnd(project);
  const { nextClaimDate, nextClaimView } = useNextClaimByProject(project);

  const linkByID = `https://app.gagarin.world/ido/${project.id}`
  if (projectStage < ProjectStages.whitelist) {
    projectStatus = `Whitelist start — ${projectWhitelistStart}`;
  } else if (
    projectStage >= ProjectStages.whitelist &&
    projectStage < ProjectStages.swapGap
  ) {
    projectStatus = `Whitelist end — ${projectWhitelistEnd}`;
  } else if (
    projectStage >= ProjectStages.swapGap &&
    projectStage < ProjectStages.swap
  ) {
    projectStatus = `Swap start — ${projectSwapStart}`;
  } else if (
    projectStage >= ProjectStages.swap &&
    projectStage < ProjectStages.fcfs
  ) {
    projectStatus = `Swap end — ${projectSwapEnd}`;
  } else if (
    projectStage >= ProjectStages.fcfs &&
    projectStage < ProjectStages.claimGap
  ) {
    projectStatus = `FCFS end — ${projectFcfsEnd}`;
  } else if (
    projectStage > ProjectStages.fcfs &&
    projectStage < ProjectStages.ended
  ) {
    projectStatus =
      nextClaimView === PAYMENTS_OVER
        ? PAYMENTS_OVER
        : `Next claim at ${formatDate(nextClaimDate)}`;
  } else if (projectStage === ProjectStages.ended) {
    projectStatus = `IDO completed`;
  }

  return (
    <div className="project">
      <div
        className="project__frame"
        style={{
          backgroundImage: `url(${projectBanner})`,
        }}
      ></div>
      <div className="project__main">
        <div className="project__top _row">
          <p className="project__title">{project.title}</p>
          <img
            src={exchangeCurrencyIcon || ""}
            alt=""
            className="project__exchange"
          />
        </div>
        <div className="project__info _row">
          <p className="project__topic">{t("Total Raise")}</p>
          <p className="project__value">{totalRaise}</p>
        </div>
        <div className="project__info _row">
          <p className="project__topic">{t("Exchange Rate")}</p>
          <p className="project__value">{exchangeRate}</p>
        </div>
        {projectStage > ProjectStages.fcfs ? (
          <div className="project-fill__row">
            <div className="project-fill__filled">
              All tokens are sold out. <br /> Thank you for your participation
            </div>
          </div>
        ) : (
          <div
            style={{
              display:
                +formatExchangeRate(project.exchangeRate) &&
                !!project.exchangeCurrency &&
                !!project.claimCurrency &&
                project.invested
                  ? "block"
                  : "none",
            }}
          >
            <div className="project__box">
              <p className="project__opens">
                {/* {stageName} {width > 743 && '—'} {stageDate} */}
                {projectStage < ProjectStages.whitelist &&
                  `Whitelist start — ${projectWhitelistStart}`}

                {projectStage >= ProjectStages.whitelist &&
                  projectStage < ProjectStages.swapGap &&
                  `Whitelist end — ${projectWhitelistEnd}`}

                {projectStage >= ProjectStages.swapGap &&
                  projectStage < ProjectStages.swap &&
                  `Swap start — ${projectSwapStart}`}

                {projectStage >= ProjectStages.swap &&
                  projectStage < ProjectStages.fcfs &&
                  `Swap end — ${projectSwapEnd}`}

                {projectStage >= ProjectStages.fcfs &&
                  projectStage < ProjectStages.claimGap &&
                  `FCFS end — ${projectFcfsEnd}`}

                {projectStage > ProjectStages.fcfs &&
                  projectStage < ProjectStages.ended &&
                  (nextClaimView === PAYMENTS_OVER
                    ? PAYMENTS_OVER
                    : `Next claim at ${formatDate(nextClaimDate)}`)}
                {projectStage === ProjectStages.ended && `IDO completed`}
              </p>
              <p className="project__percent">{percent}%</p>
            </div>
            <div className="project__progress">
              <div
                className="project__progress-line"
                style={{ width: `${percent}%` }}
              ></div>
            </div>
            <div className="project__box project__box-raise">
              <p className="project__raise project__raise-left">
                <img
                  src={exchangeCurrencyIcon || ""}
                  alt=""
                  className="project__currency"
                />
                {formatBigNumber(project.invested)}{" "}
                {`${
                  project.exchangeCurrency && project.exchangeCurrency[0].ticket
                }`}
              </p>
              <p className="project__raise project__raise-right">
                {filled} / {swap}{" "}
                {`${project.claimCurrency && project.claimCurrency[0].ticket}`}
                <img
                  src={projectToken || project.tokenLogoUrl}
                  alt=""
                  className="project__currency"
                />
              </p>
            </div>
          </div>
        )}
        <button onClick={() => {window.open(linkByID)}} className="project__button">{t("Learn more")}</button>
      </div>
    </div>
  );
};

export default Project;
