import { constants } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { ChainId, NetworkName, TierName, TierRequiredAmount } from "./enums";

export const USER_LOGIN_SIGNATURE_KEY =
  "Gagarin Launchpad sign confirmation" as const;

export const TWENTY_SECONDS = 20_000 as const;
export const TWO_MINUTES = 120_000 as const;
export const ONE_HOUR = 3_600_000 as const;
export const TWO_HOURS = 7_200_000 as const;
export const FORTY_EIGHT_HOURS = 172_800_000 as const;

export const AddressZero = constants.AddressZero;
export const MaxUint256 = constants.MaxUint256;

export const ROOT_MAINNET_ADDRESSES_MAP = {
  [ChainId.BINANCE_MAINNET]: "0xdFDeC470fcC6E1ee7fBb7A0F486F9a755216B638",
  [ChainId.CSC_MAINNET]: "0xa83132B24836141985357A36EB81D61e5b04f5Db",
  [ChainId.OKC_MAINNET]: "0x3e9041CA8D0049d1Dc6cb00B6658AC104e93Ef4b",
  [ChainId.POLYGON_MAINNET]: "0xa83132B24836141985357A36EB81D61e5b04f5Db",
  [ChainId.ETHEREUM_MAINNET]: "0xf7d409bC476D13C34545dE468A86ea9105BbbA00",
  [ChainId.BRISE_MAINNET]: "0x08726078DB4D6e92F3B0596f5f648f93EBDA62Ec",
  [ChainId.CADUCEUS_MAINNET]: "0xa83132B24836141985357A36EB81D61e5b04f5Db",
  [ChainId.TELOS_MAINNET]: "0xa83132B24836141985357A36EB81D61e5b04f5Db",
  [ChainId.OPTIMISM_MAINNET]: "",
  [ChainId.ARBITRUM_ONE]: "0xa83132B24836141985357A36EB81D61e5b04f5Db",
  [ChainId.ARBITRUM_NOVA]: "",
};
export const ROOT_TESTNET_ADDRESSES_MAP = {
  [ChainId.BINANCE_TESTNET]: "0x445102e8CA3dF63c7Dbe9E17a2a52CCAAF6fa077",
  [ChainId.CSC_TESTNET]: "0x01Ac070152856dc6DC6540de13777C8b75F2E1e9",
  [ChainId.OKC_TESTNET]: "0x01Ac070152856dc6DC6540de13777C8b75F2E1e9",
  [ChainId.POLYGON_TESTNET]: "0x6ABf6a8F888ef1A02415E11B453066A2B50aba49",
  [ChainId.ETHEREUM_GOERLI]: "0x755970EBc01b8c3991771aE9887636bc7A5E5d8A",
  [ChainId.BRISE_TESTNET]: "0x55d6E959e08ddD59CfB6f380123982c9fe94A938",
  [ChainId.CADUCEUS_TESTNET]: "0x755970EBc01b8c3991771aE9887636bc7A5E5d8A",
  [ChainId.TELOS_TESTNET]: "0x755970EBc01b8c3991771aE9887636bc7A5E5d8A",
  [ChainId.OPTIMISM_TESTNET]: "",
  [ChainId.ARBITRUM_TESTNET]: "",
};

export const ROOT_ADDRESSES_MAP = {
  [ChainId.UNSET]: ROOT_MAINNET_ADDRESSES_MAP[ChainId.BINANCE_MAINNET], // FIXME не понимаю зачем ставится бинанс
  ...ROOT_MAINNET_ADDRESSES_MAP,
  ...ROOT_TESTNET_ADDRESSES_MAP,
};

export const TIERS_TOKENS_AMOUNT_MAP = {
  [TierName.TIER_1]: parseEther(TierRequiredAmount.TIER_1),
  [TierName.TIER_2]: parseEther(TierRequiredAmount.TIER_2),
  [TierName.TIER_3]: parseEther(TierRequiredAmount.TIER_3),
  [TierName.TIER_4]: parseEther(TierRequiredAmount.TIER_4),
};

export const NODES_MAP = {
  [ChainId.UNSET]: "",
  [ChainId.BINANCE_MAINNET]: "https://bsc-dataseed.binance.org/",
  [ChainId.BINANCE_TESTNET]: "https://data-seed-prebsc-1-s3.binance.org:8545",
  [ChainId.CSC_MAINNET]: "https://rpc.coinex.net",
  [ChainId.CSC_TESTNET]: "https://testnet-rpc.coinex.net/",
  [ChainId.OKC_MAINNET]: "https://exchainrpc.okex.org",
  [ChainId.OKC_TESTNET]: "https://exchaintestrpc.okex.org",
  [ChainId.POLYGON_MAINNET]: "https://polygon-rpc.com/",
  [ChainId.POLYGON_TESTNET]: "https://rpc-mumbai.maticvigil.com",
  [ChainId.ETHEREUM_GOERLI]:
    "https://eth-goerli.g.alchemy.com/v2/V1jKV8qV4OB_myEzvSmtfadwxk4vFlEr",
  [ChainId.BRISE_MAINNET]: "https://nodes.vefinetwork.org/bitgert",
  [ChainId.BRISE_TESTNET]: "https://testnet-rpc.brisescan.com",
  [ChainId.ETHEREUM_MAINNET]: "https://rpc.ankr.com/eth",
  [ChainId.CADUCEUS_MAINNET]: "https://mainnet.block.caduceus.foundation",
  [ChainId.CADUCEUS_TESTNET]: "https://galaxy.block.caduceus.foundation",
  [ChainId.TELOS_MAINNET]: "https://mainnet.telos.net/evm",
  [ChainId.TELOS_TESTNET]: "https://testnet.telos.net/evm",
  [ChainId.OPTIMISM_MAINNET]: "https://mainnet.optimism.io",
  [ChainId.OPTIMISM_TESTNET]: "https://goerli.optimism.io",
  [ChainId.ARBITRUM_ONE]: "https://arb1.arbitrum.io/rpc",
  [ChainId.ARBITRUM_NOVA]: "https://nova.arbitrum.io/rpc",
  [ChainId.ARBITRUM_TESTNET]: "https://goerli-rollup.arbitrum.io/rpc",
};

export const NETWORK_NAMES_MAP = {
  [ChainId.UNSET]: NetworkName.UNSET,
  [ChainId.BINANCE_MAINNET]: NetworkName.BINANCE_MAINNET,
  [ChainId.BINANCE_TESTNET]: NetworkName.BINANCE_TESTNET,
  [ChainId.CSC_MAINNET]: NetworkName.CSC_MAINNET,
  [ChainId.CSC_TESTNET]: NetworkName.CSC_TESTNET,
  [ChainId.OKC_MAINNET]: NetworkName.OKC_MAINNET,
  [ChainId.OKC_TESTNET]: NetworkName.OKC_TESTNET,
  [ChainId.POLYGON_MAINNET]: NetworkName.POLYGON_MAINNET,
  [ChainId.POLYGON_TESTNET]: NetworkName.POLYGON_TESTNET,
  [ChainId.ETHEREUM_GOERLI]: NetworkName.ETHEREUM_GOERLI,
  [ChainId.ETHEREUM_MAINNET]: NetworkName.ETHEREUM_MAINNET,
  [ChainId.BRISE_MAINNET]: NetworkName.BRISE_MAINNET,
  [ChainId.BRISE_TESTNET]: NetworkName.BRISE_TESTNET,
  [ChainId.CADUCEUS_MAINNET]: NetworkName.CADUCEUS_MAINNET,
  [ChainId.CADUCEUS_TESTNET]: NetworkName.CADUCEUS_TESTNET,
  [ChainId.TELOS_MAINNET]: NetworkName.TELOS_MAINNET,
  [ChainId.TELOS_TESTNET]: NetworkName.TELOS_TESTNET,
  [ChainId.OPTIMISM_MAINNET]: NetworkName.OPTIMISM_MAINNET,
  [ChainId.OPTIMISM_TESTNET]: NetworkName.OPTIMISM_TESTNET,
  [ChainId.ARBITRUM_ONE]: NetworkName.ARBITRUM_ONE,
  [ChainId.ARBITRUM_NOVA]: NetworkName.ARBITRUM_NOVA,
  [ChainId.ARBITRUM_TESTNET]: NetworkName.ARBITRUM_TESTNET,
};

export const NETWORK_MAX_BLOCK_COUNT_MAP = {
  [ChainId.UNSET]: 0,
  [ChainId.BINANCE_MAINNET]: 5000,
  [ChainId.BINANCE_TESTNET]: 5000,
  [ChainId.CSC_MAINNET]: 5000,
  [ChainId.CSC_TESTNET]: 5000,
  [ChainId.OKC_MAINNET]: 300,
  [ChainId.OKC_TESTNET]: 300,
  [ChainId.POLYGON_MAINNET]: 1000,
  [ChainId.POLYGON_TESTNET]: 1000,
  [ChainId.ETHEREUM_GOERLI]: 5000,
  [ChainId.ETHEREUM_MAINNET]: 5000,
  [ChainId.BRISE_MAINNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.BRISE_TESTNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.CADUCEUS_MAINNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.CADUCEUS_TESTNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.TELOS_MAINNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.TELOS_TESTNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.OPTIMISM_MAINNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.OPTIMISM_TESTNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.ARBITRUM_ONE]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.ARBITRUM_NOVA]: 300, // FIXME не нашел конкретной информации. поставил наугад
  [ChainId.ARBITRUM_TESTNET]: 300, // FIXME не нашел конкретной информации. поставил наугад
};

export const EXPLORERS_MAP = {
  [ChainId.UNSET]: {
    url: "https://bscscan.com/",
    name: "Bscscan",
  },
  [ChainId.BINANCE_MAINNET]: {
    url: "https://bscscan.com/",
    name: "Bscscan",
  },
  [ChainId.BINANCE_TESTNET]: {
    url: "https://testnet.bscscan.com/",
    name: "Bscscan Testnet",
  },
  [ChainId.CSC_MAINNET]: {
    url: "https://www.coinex.net/",
    name: "CSC Explorer",
  },
  [ChainId.CSC_TESTNET]: {
    url: "https://testnet.coinex.net/",
    name: "Testnet CSC Explorer",
  },
  [ChainId.OKC_MAINNET]: {
    url: "https://www.oklink.com/okc/",
    name: "OKLink",
  },
  [ChainId.OKC_TESTNET]: {
    url: "https://www.oklink.com/okc-test/",
    name: "OKLink",
  },
  [ChainId.POLYGON_MAINNET]: {
    url: "https://polygonscan.com/",
    name: "Polygon Explorer",
  },
  [ChainId.POLYGON_TESTNET]: {
    url: "https://matic-mumbai.chainstacklabs.com/",
    name: "Mumbai Explorer",
  },
  [ChainId.ETHEREUM_GOERLI]: {
    url: "https://goerli.etherscan.io",
    name: "Goerli Testnet Explorer",
  },
  [ChainId.ETHEREUM_MAINNET]: {
    url: "https://etherscan.io/",
    name: "The Ethereum Blockchain Explorer",
  },
  [ChainId.BRISE_MAINNET]: {
    url: "https://brisescan.com/",
    name: "Brise Chain Explorer",
  },
  [ChainId.BRISE_TESTNET]: {
    url: "https://testnet-explorer.brisescan.com/",
    name: "Brise Chain testnet Explorer",
  },
  [ChainId.CADUCEUS_MAINNET]: {
    url: "https://mainnet.scan.caduceus.foundation",
    name: "CMP-Mainnet Explorer",
  },
  [ChainId.CADUCEUS_TESTNET]: {
    url: "https://galaxy.scan.caduceus.foundation",
    name: "CMP-Testnet Explorer",
  },
  [ChainId.TELOS_MAINNET]: {
    url: "https://teloscan.io",
    name: "Telos EVM Mainnet Explorer",
  },
  [ChainId.TELOS_TESTNET]: {
    url: "https://testnet.teloscan.io/",
    name: "Telos EVM Testnet Explorer",
  },
  [ChainId.OPTIMISM_MAINNET]: {
    url: "https://optimistic.etherscan.io",
    name: "Optimism Mainnet Explorer",
  },
  [ChainId.OPTIMISM_TESTNET]: {
    url: "https://optimism.io",
    name: "Optimism Testnet Explorer",
  },
  [ChainId.ARBITRUM_ONE]: {
    url: "https://arbiscan.io/",
    name: "Arbitrum One Explorer",
  },
  [ChainId.ARBITRUM_NOVA]: {
    url: "https://nova.arbiscan.io/",
    name: "Arbitrum Nova Explorer",
  },
  [ChainId.ARBITRUM_TESTNET]: {
    url: "https://goerli.arbiscan.io",
    name: "Arbitrum Testnet Explorer",
  },
};

export const NETWORK_DATA_MAP = {
  [ChainId.UNSET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.UNSET],
    node: NODES_MAP[ChainId.UNSET],
    name: NETWORK_NAMES_MAP[ChainId.UNSET],
    explorer: EXPLORERS_MAP[ChainId.UNSET],
  },
  [ChainId.BINANCE_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.BINANCE_MAINNET],
    node: NODES_MAP[ChainId.BINANCE_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.BINANCE_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.BINANCE_MAINNET],
  },
  [ChainId.BINANCE_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.BINANCE_TESTNET],
    node: NODES_MAP[ChainId.BINANCE_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.BINANCE_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.BINANCE_TESTNET],
  },
  [ChainId.CSC_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.CSC_MAINNET],
    node: NODES_MAP[ChainId.CSC_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.CSC_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.CSC_MAINNET],
  },
  [ChainId.CSC_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.CSC_TESTNET],
    node: NODES_MAP[ChainId.CSC_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.CSC_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.CSC_TESTNET],
  },
  [ChainId.OKC_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.OKC_MAINNET],
    node: NODES_MAP[ChainId.OKC_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.OKC_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.OKC_MAINNET],
  },
  [ChainId.OKC_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.OKC_TESTNET],
    node: NODES_MAP[ChainId.OKC_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.OKC_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.OKC_TESTNET],
  },
  [ChainId.POLYGON_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.POLYGON_MAINNET],
    node: NODES_MAP[ChainId.POLYGON_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.POLYGON_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.POLYGON_MAINNET],
  },
  [ChainId.POLYGON_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.POLYGON_TESTNET],
    node: NODES_MAP[ChainId.POLYGON_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.POLYGON_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.POLYGON_TESTNET],
  },
  [ChainId.ETHEREUM_GOERLI]: {
    root: ROOT_ADDRESSES_MAP[ChainId.ETHEREUM_GOERLI],
    node: NODES_MAP[ChainId.ETHEREUM_GOERLI],
    name: NETWORK_NAMES_MAP[ChainId.ETHEREUM_GOERLI],
    explorer: EXPLORERS_MAP[ChainId.ETHEREUM_GOERLI],
  },
  [ChainId.ETHEREUM_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.ETHEREUM_MAINNET],
    node: NODES_MAP[ChainId.ETHEREUM_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.ETHEREUM_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.ETHEREUM_MAINNET],
  },
  [ChainId.BRISE_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.BRISE_MAINNET],
    node: NODES_MAP[ChainId.BRISE_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.BRISE_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.BRISE_MAINNET],
  },
  [ChainId.BRISE_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.BRISE_TESTNET],
    node: NODES_MAP[ChainId.BRISE_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.BRISE_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.BRISE_TESTNET],
  },
  [ChainId.CADUCEUS_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.CADUCEUS_MAINNET],
    node: NODES_MAP[ChainId.CADUCEUS_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.CADUCEUS_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.CADUCEUS_MAINNET],
  },
  [ChainId.CADUCEUS_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.CADUCEUS_TESTNET],
    node: NODES_MAP[ChainId.CADUCEUS_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.CADUCEUS_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.CADUCEUS_TESTNET],
  },
  [ChainId.TELOS_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.TELOS_MAINNET],
    node: NODES_MAP[ChainId.TELOS_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.TELOS_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.TELOS_MAINNET],
  },
  [ChainId.TELOS_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.TELOS_TESTNET],
    node: NODES_MAP[ChainId.TELOS_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.TELOS_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.TELOS_TESTNET],
  },
  [ChainId.OPTIMISM_MAINNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.OPTIMISM_MAINNET],
    node: NODES_MAP[ChainId.OPTIMISM_MAINNET],
    name: NETWORK_NAMES_MAP[ChainId.OPTIMISM_MAINNET],
    explorer: EXPLORERS_MAP[ChainId.OPTIMISM_MAINNET],
  },
  [ChainId.OPTIMISM_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.OPTIMISM_TESTNET],
    node: NODES_MAP[ChainId.OPTIMISM_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.OPTIMISM_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.OPTIMISM_TESTNET],
  },
  [ChainId.ARBITRUM_ONE]: {
    root: ROOT_ADDRESSES_MAP[ChainId.ARBITRUM_ONE],
    node: NODES_MAP[ChainId.ARBITRUM_ONE],
    name: NETWORK_NAMES_MAP[ChainId.ARBITRUM_ONE],
    explorer: EXPLORERS_MAP[ChainId.ARBITRUM_ONE],
  },
  [ChainId.ARBITRUM_NOVA]: {
    root: ROOT_ADDRESSES_MAP[ChainId.ARBITRUM_NOVA],
    node: NODES_MAP[ChainId.ARBITRUM_NOVA],
    name: NETWORK_NAMES_MAP[ChainId.ARBITRUM_NOVA],
    explorer: EXPLORERS_MAP[ChainId.ARBITRUM_NOVA],
  },
  [ChainId.ARBITRUM_TESTNET]: {
    root: ROOT_ADDRESSES_MAP[ChainId.ARBITRUM_TESTNET],
    node: NODES_MAP[ChainId.ARBITRUM_TESTNET],
    name: NETWORK_NAMES_MAP[ChainId.ARBITRUM_TESTNET],
    explorer: EXPLORERS_MAP[ChainId.ARBITRUM_TESTNET],
  },
};

export const productionChainIds = [
  ChainId.BINANCE_MAINNET,
  ChainId.CSC_MAINNET,
  ChainId.OKC_MAINNET,
  ChainId.POLYGON_MAINNET,
  ChainId.ETHEREUM_MAINNET,
  ChainId.BRISE_MAINNET,
  ChainId.CADUCEUS_MAINNET,
  ChainId.TELOS_MAINNET,
  ChainId.ARBITRUM_ONE,
  // ChainId.ARBITRUM_NOVA,
  // ChainId.OPTIMISM_MAINNET,
];
export const developmentChainIds = [
  ChainId.BINANCE_TESTNET,
  ChainId.CSC_TESTNET,
  ChainId.OKC_TESTNET,
  ChainId.CADUCEUS_TESTNET,
  ChainId.TELOS_TESTNET,
  // ChainId.OPTIMISM_TESTNET,
  // ChainId.ARBITRUM_TESTNET,
  // ChainId.ETHEREUM_GOERLI,
  // ChainId.BRISE_TESTNET, // FIXME
];

export const allChainIds = [...developmentChainIds, ...productionChainIds];
