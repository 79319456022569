import bnb from "../assets/img/binance.svg";
import csc from "../assets/img/csc.svg";
import eth from "../assets/img/ethereum.svg";
import okc from "../assets/img/okc.svg";
import polygon from "../assets/img/polygon.svg";

export enum Image {
  BANNER = "banner",
  LOGO = "logo",
  TOKEN_LOGO = "tokenLogo",
  NETWORK = "network",
}

export enum Language {
  EN = "en",
  RU = "ru",
}
export enum LinkName {
  Telegram = "telegram",
  Twitter = "twitter",
  Website = "website",
  Discord = "discord",
  Instagram = "instagram",
  Medium = "medium",
  Document = "document",
  Bscscan = "bscscan",
  Pitchdeck = "pitchdeck",
  Whitepaper = "whitepaper",
  Tokenomics = "tokenomics",
}

export enum Translation {
  DESCRIPTION = "description",
  VESTING = "vesting",
  TITLE = "title",
  TEXT = "text",
  LINK = "link",
}
export enum NetworkName {
  UNSET = "TBA",
  BINANCE_MAINNET = "BNB Chain",
  BINANCE_TESTNET = "BNB Chain Testnet",
  CSC_MAINNET = "CSC",
  CSC_TESTNET = "CSC Testnet",
  OKC_MAINNET = "OKC",
  OKC_TESTNET = "OKC Testnet",
  POLYGON_MAINNET = "Polygon",
  POLYGON_TESTNET = "Polygon Testnet",
}

export const NETWORK_ICONS: {[key: string]: string} = {
  "Ethereum": eth,
  "BNB Chain": bnb,
  "BNB Chain Testnet": bnb,
  "CSC": csc,
  "CSC Testnet": csc,
  "OKC": okc,
  "OKC Testnet": okc,
  "Polygon": polygon,
  "Polygon Testnet": polygon,
};
export enum Round {
  SEED = "seed",
  PRIVATE = "private",
}
// export enum ExchangeCurrency {
//   USDT = "ustd",
//   BUSD = "busd",
//   USDC = "usdc",
// }

export type ExchangeCurrency = {
  id: string;
  name: string;
  icon: string;
};

export type ImageType = {
  id: string;
  path: string;
  type: Image;
  idoId?: string;
};
export type TTranslation = {
  id: string;
  type: Translation;
  locale: Language;
  value: string;
};
export type Social = {
  type: LinkName;
  link: string;
};
export type TPoolInfo = {
  id: string;
  weight?: number;
  network: NetworkName;
  TGEDate: number | "ATP";
  tokenSymbol: string;
  exchangeCurrency: ExchangeCurrency[];
  round: Round;
  visible?: boolean;
  translations?: TTranslation[];
  images?: ImageType[];
  socials: Social[];
};

export type TProjectActivity = {
  id: string;
  weight?: number;
  visible?: boolean;
  translations?: TTranslation[];
  images?: ImageType[];
};
