import { MarketingActivityType } from "../sdk";
import iActiveUserInactive from "../assets/img/projects/activities/Active-User-Small.svg";
import iActiveUserActive from "../assets/img/projects/activities/ActiveUser-Big.svg";
import iAmaActive from "../assets/img/projects/activities/AMA-Big.svg";
import iAmaInactive from "../assets/img/projects/activities/AMA-Small.svg";
import iAnotherActive from "../assets/img/projects/activities/Another-Big.svg";
import iAnotherInactive from "../assets/img/projects/activities/Another-Small.svg";
import iGiveawayActive from "../assets/img/projects/activities/Giveaway-Big.svg";
import iGiveawayInactive from "../assets/img/projects/activities/Giveaway-Small.svg";

export const obeliskId = "6288fd334ff612b46284d61c";
export const elfMatrixId = "629e2d61740749b99a21993e";
export const shibafriendId = "628bada4adf4d2ccf11cd87a";
export const tagoverseId = "62f51dde50ce727ee75fa695";
export const movensId = "62fbc1076830331e0dcae896";
export const swordOfBloodId = "62f90b41610636ed48a39dda";
export const bizwerseWorldId = "633be409fab725bd2f149275";
export const sportzchain = "6308955dcee8aac181dd0fb8";
export const blockombatId = "62f6804a75e960be826fb925";
export const aftermathId = "6331ea5cb530dbf47e7f566b";
export const keedxId = "633c4fc4a5fe15d1e25f003f";

export const ACTIVITY_DATE_FORMAT: string = "DD MMM";
export const ACTIVITIES_ICON_MAP = {
  [MarketingActivityType.AMA]: {
    inactive: iAmaInactive,
    active: iAmaActive,
  },
  [MarketingActivityType.GIVEAWAY]: {
    inactive: iGiveawayInactive,
    active: iGiveawayActive,
  },
  [MarketingActivityType.ANOTHER]: {
    inactive: iAnotherInactive,
    active: iAnotherActive,
  },
  [MarketingActivityType.ASK_AND_WIN]: {
    inactive: iActiveUserInactive,
    active: iActiveUserActive,
  },
};

export const refundedProjectsId = [obeliskId, elfMatrixId, shibafriendId, blockombatId];
export const postponedProjects = [tagoverseId, movensId, swordOfBloodId];
export const expandedProjects: string[] = [];
export const privateSwapProjects: string[] = [bizwerseWorldId, keedxId];