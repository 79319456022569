import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import openIcon from "../../assets/img/open.svg";
import "./faq.section.sass";

const FAQSection = () => {
  const [state, setState] = useState("")
  const handle = (str : string, state : string) => {
    if (str == state) {
      setState("")
    } else {
      setState(str)
    }
  }
  const {t} = useTranslation()
  return (
    <div className="faq">
      <h3 className="title faq__title">FAQ</h3>
      <div className="faq__items">
        <FAQItem

          question={t("faq._1.title")}
          answer={t("faq._1.desc")}
          handler={handle}
          state={state}
        />
        <FAQItem

          question={t("faq._2.title")}
          answer={<Trans i18nKey={"faq._2.desc"}><br/></Trans>}
          handler={handle}
          state={state}
        />
        <FAQItem

          question={t("faq._3.title")}
          answer={<Trans i18nKey={"faq._3.desc"}><br/></Trans>}

          handler={handle}
          state={state}
        />
        <FAQItem

          question={t("faq._4.title")}
          answer={<Trans i18nKey={"faq._4.desc"}><br/><a href="https://gagarin-launchpad.gitbook.io/internal-wiki/"></a></Trans>}

          handler={handle}
          state={state}
        />
        <FAQItem

          question={t("faq._5.title")}
          answer={<Trans i18nKey={"faq._5.desc"}><br/></Trans>}

          handler={handle}
          state={state}
          />


      </div>
    </div>
  );
};

type FAQ = {
  question: string;
  answer: any;
  state: string;
  handler: any;
};

const FAQItem = ({ question, answer, state, handler }: FAQ) => {
  const opened = state == question
  return (
    <div className="faq__item" onClick={() => handler(question, state)}>
      <div className="faq__top">
        <p className="faq__question">{question}</p>
        <img src={openIcon} alt="" className={`faq__icon ${opened ? 'opened' : ''}`} />
      </div>
      {opened && <div className="faq__body">{answer}</div>}
    </div>
  );
};


export default FAQSection
