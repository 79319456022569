import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import crossIcon from '../../assets/img/cross.svg'
import documentIcon from '../../assets/img/pitch.svg'
import { Image, TPoolInfo, Translation } from '../../redux/gft.types'
import { DocsLinkName } from '../../sdk'
import { useGetTranslations } from '../hooks'
import { LinkName, SOCIAL_LINKS_ICONS } from '../types'
import Button from './button'
import './pool.modal.sass'

type Props = {
    pool: TPoolInfo,
    open: boolean,
    handleClose: any
}



const PoolModal = ({pool, open, handleClose} : Props) => {

    const bannerImage = pool.images?.find(({type}) => type == Image.BANNER )?.path
    const tokenLogo = pool.images?.find(({type}) => type == Image.TOKEN_LOGO )?.path
    const logo = pool.images?.find(({type}) => type == Image.LOGO )?.path
    const networkLogo = pool.images?.find(({type}) => type == Image.NETWORK )?.path
    const title = useGetTranslations(Translation.TITLE, pool.translations || [])
    const desc = useGetTranslations(Translation.DESCRIPTION, pool.translations || [])
    const {t} = useTranslation()
  const link = useGetTranslations(Translation.LINK, pool.translations || []);




    return <ReactModal isOpen={open} className="Modal"
    shouldCloseOnOverlayClick
    onRequestClose={handleClose}
    overlayClassName="Overlay" style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 200,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)",
            border: '1px solid rgba(255, 255, 255, 0.1)',
            // background: "none",
            // borderRadius: "13px",
            WebkitOverflowScrolling: 'touch',

            outline: 'none',
          }
    }}>
        <div className="pool-modal__frame" style={{
            backgroundImage: `url(${bannerImage})`
        }}>
          <img src={crossIcon} alt="close" onClick={handleClose} className="pool-modal__close" />
            <img src={logo || ""} alt="" className="pool-modal__logo" />
        </div>
        <div className="pool-modal__content">
            <div className="pool-modal__title">{title}</div>
            <div className="pool-modal__socials">
                {pool.socials && pool.socials?.map(el => {
                    if (
                        el.type.toString().toLowerCase() === DocsLinkName.Pitchdeck ||
                        el.type.toString().toLowerCase() === DocsLinkName.Whitepaper ||
                        el.type.toString().toLowerCase() === 'litepaper' ||
                        el.type.toString().toLowerCase() === DocsLinkName.Tokenomics
                    ) {
                      return null
                    }
                    return <a href={el.link} className="pool-modal__social">
                        <img src={SOCIAL_LINKS_ICONS[el.type.toString().toLowerCase() as LinkName] || SOCIAL_LINKS_ICONS.website} alt={el.type} className="pool-modal__docicon" />
                    </a>
                })}
            </div>
            <div className="pool-modal__docs">
            {pool.socials && pool.socials?.map((el) => {
            if (
              el.type.toString().toLowerCase() === DocsLinkName.Pitchdeck ||
              el.type.toString().toLowerCase() === DocsLinkName.Whitepaper ||
              el.type.toString().toLowerCase() === 'litepaper' ||
              el.type.toString().toLowerCase() === DocsLinkName.Tokenomics
            ) {
              return (
                <a className="pool-modal__doclink" href={el.link}>
                  <img src={documentIcon} alt="" className="pool-modal__docicon" />
                  {t(`${el.type.charAt(0).toUpperCase() + el.type.slice(1)}`)}
                </a>
              );
            }
            return null;
          })}
            </div>
            <div className="pool-modal__desc">
                {desc}
            </div>
            <Button className='pool-modal__button' onClick={() => window.open(link)}>{t("invest")}</Button>
            <p className="pool-modal__disclaimer">
      <span>*</span> {t("poolDisclaimer")}
      </p>
        </div>

    </ReactModal>
}

export default PoolModal
