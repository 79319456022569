export const LINKS = {
    servicesLanding: "https://services.gagarin.world/",
    launchpad: "https://app.gagarin.world",
    projects: 'https://app.gagarin.world/?projectFilter=Upcoming',
    applyIdo: 'https://docs.google.com/forms/d/e/1FAIpQLSdwpbewPKWrRxxDcYjG2VjmI4S7tkAh-Ho7O18tkCuVvcahSg/viewform',
    applyIncubation: 'https://docs.google.com/forms/d/e/1FAIpQLScOHK-xrgfNiTX4B4xN9XpKwVQ2Ll_zMhWCCSmhvf6F6AyuoA/viewform',
    applyIno: "https://forms.gle/juKyVZnYBZDcQ2f87",
    landing: 'https://gagarin.world/',
    ido: 'https://app.gagarin.world/?projectFilter=Live',
    ino: '',
    presentation1: "/Gagarin.World.pdf",
    presentation2: "/GAGARIN_FUNDRAISING_TOOL.pdf",
    presentation3: "/GAGARIN_extra_services.pdf",
    referral: "https://forms.gle/9DaAVJ3swKUvosXr7",
    investors: 'https://private.gagarin.world/auth',
    experts: "https://docs.google.com/forms/d/e/1FAIpQLSfGAK9VwbP5odyqcuwHTLnUklvOOTwGIh8Rj4xmgFQinp0JHg/viewform?usp=send_form",
    ambassador: 'https://forms.gle/9DaAVJ3swKUvosXr7',
    partners: "https://docs.google.com/forms/d/e/1FAIpQLScsSy_3HCxMID415WgRXbP2WFvzL_qkPX4n_w80VHENHngCLg/viewform",
    rsquad: "https://rsquad.io/",
    socials: {
        twitter: 'https://twitter.com/GAGARIN_World',
        discord: "https://discord.com/invite/6FPvg5RDbc",
        medium: 'https://medium.com/@GAGARIN.World',
        linkedIn: "https://www.linkedin.com/company/gagarin-launchpad/"
    },
    telegram: {
        en: "tg://join?invite=nzU2IxjL9no1NjIy",
        ru: "tg://join?invite=yuqPVLCY4aFiNDEy",
        tur: "tg://resolve?domain=GAGARIN_Launchpad_Chat_TUR",
        cn: "tg://resolve?domain=Gagarin_Launchpad_Chat_Ch",
        announcesEn: "https://t.me/gagarin_launchpad",
        announcesRu: "https://t.me/gagarin_launchpad_ru"
    },
    taplink: 'https://taplink.cc/gagarin.launchpad',
    service: "/GGR_Terms_of_Use_v01.pdf",
    privacy: "/GGR_Privacy_Policy_v01.pdf",
    documentation: "https://gagarin-launchpad.gitbook.io/internal-wiki/",
    whitepaperEn: "/GAGARIN_Whitepaper_EN.pdf",
    tokenomicsEn: "/GAGARIN_Tokenomics_EN.pdf",
    applyservices: 'https://forms.gle/juKyVZnYBZDcQ2f87',

    submitYourProject: "https://forms.gle/F8mFumpZggy4TvBT9",
    tgBot: 'https://t.me/gft_support_bot'


}
