import banner from "../../../assets/img/pools/banner-totkit.svg";
import token from "../../../assets/img/pools/token-totkit.png";
import usdt from "../../../assets/img/tokens/usdt.png";

const OCP = {
  id: "1",
  tokenSymbol: "CRUMB",
  TGEDate: 1680123600,
  network: "BNB Chain",
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://totkit.org",
      type: "Website",
      projectId: "1",
    },
    {
      id: "2",
      link: "https://drive.google.com/file/d/1cRHo6pBj5GIQOrc8AaMbYA5QzQ_koLIh/view?usp=sharing",
      type: "Litepaper",
    },
    {
      id: "3",
      link: "https://drive.google.com/file/d/1JzjFf1aTaBZg_D-b51K5SDJMGvmtQ1Aq/view?usp=sharing",
      type: "Tokenomics",
    },
    {
      id: "7",
      link: "http://t.me/totkitnews",
      type: "Telegram",
    },
    {
      id: "6",
      link: "http://t.me/totkitbot",
      type: "Telegram",
    },
    {
      id: "2",
      link: "https://twitter.com/totkitnews",
      type: "Twitter",
      projectId: "1",
    },
  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1",
    },
    {
      id: "2",
      path: '',
      type: "logo",
      projectId: "1",
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1",
    },
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "Totkit",
      projectId: "1",
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: `TOTKIT is a comprehensive platform providing NFT, token and game monitoring services on The Open Network blockchain.

      By conducting comprehensive research, providing tools and resources, we strive to provide a wide range of services for a better experience for any user with the blockchain.

      Totkit's goal is to create an easy-to-use platform that will allow users to track the market and your digital assets in real time and receive sound investment decisions, using the tools of Totkit - The Open Network blockchain analysis and monitoring service.

      The totkit platform uses tokens issued on the TON network, called Crumb Coin ($CRUMB is a token of the BRAD x Totkit ecosystem), which are used as the internal currency of the platform.


      $CRUMB is designed for various transactions within the platform and can be used by investors in different ways: starting with the usual subscription payment, ending with the purchase of premium features, payment for additional services or expanding their capabilities within the platform

      `,
      projectId: "1",
    },
    // {
    //   id: "3",
    //   type: "description",
    //   locale: "ru",
    //   value: `OCP токен это глобальный проект, задачей которого является построение глобальной экосистемы по принципу: «Единый мир, объединенный через знания и обмен». OCP создает систему вознаграждений, где каждый человек, который использует и поддерживает OCP, получает награду. Это могут быть токены, ваучеры от партнерских отраслей, скидки и даже участие в прибыли отдельных проектов. OCP станет будущим платежей и основой для преодоления безработицы, поддержки образования и инновационного бизнеса по всему миру.
    //   Материнской компание OCP  является некоммерческая организация OTI которая имеет 16 офисов по всему миру, включая Соединенное Королевство и Кипр. С 2012 года OTI помогла получить образование, освоить новую профессию и получить новые возможности более 5 млн человек.`,
    //   projectId: "1",
    // },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "50% at TGE, then in equal installments every month for 6 months",
      projectId: "1",
    },
    {
      id: "5",
      type: "vesting",
      locale: "ru",
      value: "50% на TGE, затем в равных долях каждый месяц в течение 6 месяцев",
      projectId: "1",
    },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://fundraising.gagarin.world/form/63f8b065cbb1b69079ef16ac",
      projectId: "1",
    },
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "usdt",
      icon: usdt,
    },
  ],
};

export default OCP;
