import banner1 from '../../../assets/img/activities/1.png'
import banner2 from '../../../assets/img/activities/2.png'
import banner3 from '../../../assets/img/activities/3.png'
import banner4 from '../../../assets/img/activities/4.png'
import banner5 from '../../../assets/img/activities/5.png'


const ACTIVITIES = [
  {
    id: "1",
    visible: true,
    translations: [
      {
        id: "13",
        type: "title",
        locale: "en",
        value: "User Activity Program",
        projectActivityId: "1",
      },
      {
        id: "14",
        type: "title",
        locale: "ru",
        value: " Программа активности пользователей",
        projectActivityId: "1",
      },
      {
        id: "15",
        type: "description",
        locale: "ru",
        value: "Система репутации и опыта в комьюнити GAGARIN Launchpad",
        projectActivityId: "1",
      },
      {
        id: "16",
        type: "description",
        locale: "en",
        value: "Reputation and experience system in the GAGARIN Launchpad community",
        projectActivityId: "1",
      },
      {
        id: "17",
        type: "link",
        locale: "en",
        value:
          "https://medium.com/@GAGARIN.World/the-gagarin-team-launches-the-user-activity-program-4dc733961d99",
        projectActivityId: "1",
      },
      {
        id: "18",
        type: "link",
        locale: "ru",
        value: "https://medium.com/@GAGARIN.World/ff77d3ecd177",
        projectActivityId: "1",
      },
    ],
    images: [
      {
        id: "7",
        path: banner1,
        type: "banner",
        projectActivityId: "1",
      },
    ],
  },
  {
    id: "2",
    visible: true,
    translations: [
      {
        id: "19",
        type: "title",
        locale: "en",
        value: "GAGARIN GLOBAL REFERRAL PROGRAM",
        projectActivityId: "2",
      },
      {
        id: "20",
        type: "title",
        locale: "ru",
        value: "ГЛОБАЛЬНАЯ РЕФЕРАЛЬНАЯ ПРОГРАММА GAGARIN",
        projectActivityId: "2",
      },
      {
        id: "21",
        type: "description",
        locale: "ru",
        value:
          "Пока на рынке затишье GAGARIN запускает глобальную реферальную программу, которая станет настоящей находкой для простых участников сообщества  и лидеров мнений!",
        projectActivityId: "2",
      },
      {
        id: "22",
        type: "description",
        locale: "en",
        value:
          "While the market is calm, GAGARIN is launching a global referral program which will be a real find for community members and opinion leaders!",
        projectActivityId: "2",
      },
      {
        id: "23",
        type: "link",
        locale: "en",
        value: "https://medium.com/@GAGARIN.World/gagarin-global-referral-program-7e2973ae3734",
        projectActivityId: "2",
      },
      {
        id: "24",
        type: "link",
        locale: "ru",
        value:
          "https://medium.com/@GAGARIN.World/%D0%B3%D0%BB%D0%BE%D0%B1%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D1%80%D0%B5%D1%84%D0%B5%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-gagarin-eed632a1d7be",
        projectActivityId: "2",
      },
    ],
    images: [
      {
        id: "8",
        path: banner2,
        type: "banner",
        projectActivityId: "2",
      },
    ],
  },
  {
    id: "3",
    visible: true,
    translations: [
      {
        id: "25",
        type: "link",
        locale: "ru",
        value: "https://t.me/gagarin_launchpad_ru/460",
        projectActivityId: "3",
      },
      {
        id: "26",
        type: "link",
        locale: "en",
        value: "https://t.me/gagarin_launchpad/766",
        projectActivityId: "3",
      },
      {
        id: "27",
        type: "description",
        locale: "en",
        value:
          "From October, 31 to November, 09 complete tasks and become one of the owners of $PST tokens!",
        projectActivityId: "3",
      },
      {
        id: "28",
        type: "description",
        locale: "ru",
        value:
          "С 31 октября по 09 ноября выполняй социальные задания и стань одним из обладателей токенов $PST ",
        projectActivityId: "3",
      },
      {
        id: "29",
        type: "title",
        locale: "ru",
        value: "Crypto Pirates Присоединяйся! Спрашивай! Выигрывай!",
        projectActivityId: "3",
      },
      {
        id: "30",
        type: "title",
        locale: "en",
        value: "Crypto Pirates  JOIN! ASK! WIN!",
        projectActivityId: "3",
      },
    ],
    images: [
      {
        id: "9",
        path: banner3,
        type: "banner",
        projectActivityId: "3",
      },
    ],
  },
  {
    id: "4",
    visible: true,
    translations: [
      {
        id: "31",
        type: "title",
        locale: "en",
        value: "Quiz from Crypto Pirates and GAGARIN",
        projectActivityId: "4"
      },
      {
        id: "32",
        type: "title",
        locale: "ru",
        value: "Викторина от Crypto Pirates и GAGARIN",
        projectActivityId: "4"
      },
      {
        id: "33",
        type: "description",
        locale: "ru",
        value: "С 31 октября по 04 ноября ответьте правильно на вопросы от Crypto Pirates и получите приз один из трех уникальных Crypto Pirates NFT",
        projectActivityId: "4"
      },
      {
        id: "34",
        type: "description",
        locale: "en",
        value: "From 31 October to 04 November answer correctly the questions from Crypto Pirates and get prize one of 3 marketable Crypto Pirates NFT unique",
        projectActivityId: "4"
      },
      {
        id: "35",
        type: "link",
        locale: "ru",
        value: "https://t.me/gagarin_launchpad_ru/461",
        projectActivityId: "4"
      },
      {
        id: "36",
        type: "link",
        locale: "en",
        value: "https://t.me/gagarin_launchpad/767",
        projectActivityId: "4"
      }
    ],
    images: [
      {
        id: "10",
        path: banner4,
        type: "banner",
        projectActivityId: "4"
      }
    ]
  },
  {
    id: "5",
    visible: true,
    translations: [
      {
        id: "37",
        type: "link",
        locale: "en",
        value: "https://t.me/gagarin_launchpad/770",
        projectActivityId: "5"
      },
      {
        id: "38",
        type: "link",
        locale: "ru",
        value: "https://t.me/gagarin_launchpad_ru/464",
        projectActivityId: "5"
      },
      {
        id: "39",
        type: "description",
        locale: "en",
        value: "From November, 01 toNovember, 15 complete tasks and become one of the owners of $GFI tokens!",
        projectActivityId: "5"
      },
      {
        id: "40",
        type: "description",
        locale: "ru",
        value: "С 01 Ноября по 15 Ноября выполняй социальные задания и стань одним из обладателей токенов $GFI",
        projectActivityId: "5"
      },
      {
        id: "41",
        type: "title",
        locale: "en",
        value: "GoatFI JOIN! ASK! WIN!",
        projectActivityId: "5"
      },
      {
        id: "42",
        type: "title",
        locale: "ru",
        value: "GoatFI Присоединяйся! Спрашивай! Выигрывай!",
        projectActivityId: "5"
      }
    ],
    images: [
      {
        id: "11",
        path: banner5,
        type: "banner",
        projectActivityId: "5"
      }
    ]
  },

];

export default ACTIVITIES;
