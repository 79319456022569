

import BusdIcon from '../assets/img/tokens/busd.svg'
import UsdtIcon from '../assets/img/tokens/usdt.png'

import { ChainId } from '../app/enums'
import bscscanIcon from '../assets/img/projects/socials/bscscan.svg'
import discordIcon from '../assets/img/projects/socials/discord.svg'
import websiteIcon from '../assets/img/projects/socials/globe.svg'
import instagramIcon from '../assets/img/projects/socials/instagram.svg'
import mediumIcon from '../assets/img/projects/socials/medium.svg'
import documentIcon from '../assets/img/projects/socials/pitch.svg'
import telegramIcon from '../assets/img/projects/socials/telegram.svg'
import twitterIcon from '../assets/img/projects/socials/twitter.svg'
import youtubeIcon from '../assets/img/projects/socials/youtube.svg'
import { Ido, Ino } from "../sdk"

export type TPartner = {
  link: string,
  image: string
}
export type KeyValueItem = {
  itemKey: string;
  children: JSX.Element;
};

export type Project = Ido | Ino;

export type TDefaultResponse<D = TDefaultData, M = TDefaultMeta> = {
  data: D;
  meta: M;
};

export type TDefaultData = {};

export type TDefaultMeta = {};
export enum ProjectStatus {
  NOT_DEPLOYED = "not deployed",
  UPCOMING = "upcoming",
  REDEEM = "redeem",
  FCFS = "fcfs",
  CLAIM = "claim",
  DISTRIBUTED = "distributed",
}

export type TRawProject = {
  id: string;
  weight?: number; // вес для сортировки
  createdAt: number; // Date.now()
  updateddAt: number; // Date.now()
  blockchainId: number;
  title: string;
  description: string;
  exchangeRate: string | ""; // BigNumber.toString()
  whitelistStartDate: number; // Date.now()
  whitelistEndDate: number; // Date.now()
  swapStartDate: number; // Date.now()
  swapEndDate: number; // Date.now()
  fcfsEndDate: number; // Date.now()
  fcfsMaxCount: string; // BigNumber.toString()
  totalRaise: string | ""; // BigNumber.toString()
  whitelistCount: string;
  idoBannerUrl?: string;
  idoLogoUrl?: string;
  tokenLogoUrl?: string;
  network: ChainId;
  claimType: string;
  payments: ProjectPayment[];
  vestingSchendule: string;
  exchangeCurrency?: CurrencyInfo[];
  claimCurrency?: CurrencyInfo[];
  applications?: TApplication[];
  status: ProjectStatus;
  visibility: boolean;
  refundable?: boolean;
  // translations?: Translation[];
  fcfsFree?: boolean;
  // activities?: MarketingActivity[];
};
export type ProjectPayment = {
  date: number;
  percent: number;
};

export type TCurrencyData = {
  ticket: CurrencyTicket;
  address: string;
};

export enum CurrencyTicket {
  BUSD = "BUSD",
  USDT = "USDT",
}

export type TPayments = {
  date: number;
  percent: number;
};

export type TApplication = {
  id: number;
  userId: string;
  idoId: number;
  amount: string;
  status: "inreview" | "approved" | "rejected";
  fcfsAmount: string;
};

export type TState =
  | "not deployed"
  | "upcoming"
  | "redeem"
  | "fcfs"
  | "claim"
  | "distributed";

export type Link = {
  id: number;
  idoId: number;
  name: LinkName;
  link: string;
  isTask: boolean;
  applicationLinkStatuses?: LinkStatus[];
};

export enum LinkName {
  Telegram = "telegram",
  Twitter = "twitter",
  Website = "website",
  Discord = "discord",
  Instagram = "instagram",
  Medium = "medium",
  Document = "document",
  Bscscan = "bscscan",
  Youtube = "youtube",
}

export type LinkStatus = {
  id: number;
  applicationId: number;
  linkId: number;
  handle: string;
  status: string;
};


export const EXCHANGE_CURRENCY_ICON_MAP = {
  [CurrencyTicket.BUSD]: BusdIcon,
  [CurrencyTicket.USDT]: UsdtIcon,
};

export enum ProjectStages {
  whitelistTba = 1, // дата whitelist неизвестна
  whitelistGap, // дата whitelist известна, но не наступила
  whitelist, // whitelist открыт
  swapGap, // whitelist закрыт, но лотерея не проведена
  swapGapWithResults, // whitelist закрыт, лотерея проведена, но дата swap не наступила
  swap, // swap начался
  fcfs, // swap закончился, fcfs начался
  claimGap, // fcfs закончился, дата claim не наступила
  claim, // claim начался
  ended,
  refund, // возврат средств
  refundTge, // ручной возврат средств после TGE
  pause,
}

export const DEFAULT_DATE_FORMAT: string = "DD MMMM, YYYY HH:mm";
export const DEFAULT_DATE_FORMAT_SHORT: string = "DD MMMM, YYYY";
export const SOCIAL_LINKS_ICONS = {
  [LinkName.Telegram]: telegramIcon,
  [LinkName.Twitter]: twitterIcon,
  [LinkName.Bscscan]: bscscanIcon,
  [LinkName.Discord]: discordIcon,
  [LinkName.Instagram]: instagramIcon,
  [LinkName.Medium]: mediumIcon,
  [LinkName.Website]: websiteIcon,
  [LinkName.Document]: documentIcon,
  [LinkName.Youtube]: youtubeIcon

}
export type CurrencyInfo = {
  ticket: CurrencyTicket;
  address: string;
};


export enum DocsLinkName {
  Pitchdeck = "pitchdeck",
  Whitepaper = "whitepaper",
  Tokenomics = "tokenomics",
}
