import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Ido, Ino } from "../sdk";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROJECTS_API}`,
  }),
  endpoints: (builder) => ({
    idos: builder.query<Ido[], void>({
      query: () =>
        `/idos?filter=${JSON.stringify({
          order: ["weight DESC"],
          include: [
            { relation: "exchangeCurrency" },
            { relation: "claimCurrency" },
            { relation: "payments" },
            { relation: "links" },
            { relation: "translations" },
            { relation: "modalFields" },
            {
              relation: "activities",
              scope: { include: [{ relation: "translations" }] },
            },
          ],
          where: {visibility : true , refundType: {neq: 'refunded'}}
        })}`,
    }),

    inos: builder.query<Ino[], void>({
      query: () =>
        `/inos?filter=${JSON.stringify({
          order: ["weight DESC"],
          include: ["links", "exchangeCurrencies", "activities", "nftBoxes"],
          where: { visible: true },
        })}`,
    }),
  }),
});

export const { useIdosQuery, useInosQuery } = projectsApi;
