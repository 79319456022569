import { BigNumber, BigNumberish } from "ethers";
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from "ethers/lib/utils";
import getSupportedChains from "./chains";
import { Ido } from "./types";

/**
 *
 * @param decimals 18 by default
 * @returns BigNumber amount with 18 decimals
 */
export const parseDecimals = (
  amount: BigNumberish = BigNumber.from(0),
  decimals: BigNumberish = 18
): BigNumber => {
  return parseEther(formatUnits(amount, decimals));
};

/**
 *
 * @param decimals 18 by default
 * @returns BigNumber amount with 18 decimals
 */
export const formatDecimals = (
  amount: BigNumberish = BigNumber.from(0),
  decimals: BigNumberish = 18
): BigNumber => {
  return parseUnits(formatEther(amount), decimals);
};

/**
 *
 * @param amount in stable coins
 * @param decimals 18 by default
 * @returns BigNumber amount of tokens by exchangeRate
 */
export const getTokensAmount = (
  exchangeRate: BigNumberish,
  amount: BigNumber,
  decimals: BigNumberish = 18
): BigNumber => {
  const etherAmount = parseDecimals(amount, decimals);

  return etherAmount.mul(parseEther("1")).div(exchangeRate);
};

/**
 *
 * @param tokensAmount in project tokens
 * @param decimals 18 by default
 * @returns BigNumber amount of stable coins by exchangeRate
 */
export const getStableAmount = (
  exchangeRate: BigNumberish,
  tokensAmount: BigNumber,
  decimals: BigNumberish = 18
): BigNumber => {
  const etherTokensAmount = parseDecimals(tokensAmount, decimals);

  return etherTokensAmount.mul(exchangeRate).div(parseEther("1"));
};

export const getHexFromId = (projectId: string = ""): string => {
  return "0x" + projectId;
};

export const getIdFromHex = (hex: BigNumber): string => {
  return hex.toHexString().slice(2);
};

export const isFullDate = (timestamp: number): boolean => {
  const seconds = new Date(timestamp).getSeconds();
  return !seconds;
};

export const getChainData = (chainId: number) => {
  const supportedChains = getSupportedChains();

  return supportedChains.find((chain) => chain.chain_id === chainId);
};

export const ellipseAddress = (address = "", width = 4): string => {
  if (!address) return "";

  return `${address.slice(0, width + 2)}...${address.slice(-width)}`;
};

export const getArrayOfUniqueObjects = <T = { [key: string]: any }>(
  array: T[],
  key: keyof T
) => {
  const { uniqueArray } = array.reduce<{
    map: Map<T[keyof T], boolean>;
    uniqueArray: T[];
  }>(
    (result, activity) => {
      if (result.map.get(activity[key])) return result;

      result.map.set(activity[key], true);
      result.uniqueArray.push(activity);
      return result;
    },
    {
      map: new Map<T[keyof T], boolean>(),
      uniqueArray: [],
    }
  );

  return uniqueArray;
};

export const getNumberWithSpaces = (number: string | number): string => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return parts.join(".");
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const isIdo = (project: any): project is Ido => {
  return project?.visibility !== undefined;
};

export const timeIsOver = (date?: number) => {
  if (!date) return true;
  if (Date.now() >= date) return true;
  return false;
};
