import banner from "../../../assets/img/pools/banner-wez.jpg";
import logo from "../../../assets/img/pools/logo-wez.svg";
import token from "../../../assets/img/pools/token-wez.png";
import usdt from "../../../assets/img/tokens/usdt.png";

const WESLEEP = {
  id: "1",
  tokenSymbol: "WEZ",
  network: "BNB Chain",
  TGEDate: 1676419200,
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://wesleep.io/",
      type: "Website",
      projectId: "1",
    },
    {
      id: "7",
      link: "https://medium.com/@WeSleep  ",
      type: "Medium",
    },
    {
      id: "2",
      link: "https://twitter.com/WeSleepOfficial ",
      type: "Twitter",
      projectId: "1",
    },

    {
      id: "5",
      link: "https://discord.gg/wesleep ",
      type: "Discord",
      projectId: "1",
    },


  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1",
    },
    {
      id: "2",
      path: logo,
      type: "logo",
      projectId: "1",
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1",
    },
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "WeSleep",
      projectId: "1",
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: `WeSleep is a Web3 app with inbuilt Game-Fi elements rewarding users for their sleep time on a daily basis as well as incentivising them to build a healthier and more active lifestyle. At its core WeSleep app will allow users to monetise their sleep time by rewarding them with $ZZZ tokens. In order to be eligible for rewards users will need to hold at least one Sleepie, that has a specific set of attributes such as drowsiness, fatigue, luck or devotion, which altogether define a daily rewards cap. In addition, users will get Daily Tasks such as: - Walk 10 000 steps; - Burn 1 800 calories. On the top of that, in order to create a long-term sustainable business model WeSleep is combining Web3 (sleep2earn) and Web2 (b2b2c marketplace) business approaches. Such strategy allows to establish an efficient revenue stream, which would then to be reinvested back into the ecosystem tokens: governance and rewards, in order to keep their value in a long-term.
      `,
      projectId: "1",
    },
    {
      id: "3",
      type: "description",
      locale: "ru",
      value: `WeSleep — это приложение Web3 со встроенными элементами Game-Fi, которое ежедневно вознаграждает пользователей за время сна, а также побуждает их вести более здоровый и активный образ жизни. По своей сути приложение WeSleep позволит пользователям монетизировать время сна, вознаграждая их токенами $ZZZ. Чтобы иметь право на получение вознаграждения, пользователям необходимо иметь хотя бы одного Sleepie с определенным набором атрибутов, таких как сонливость, усталость, удача или преданность, которые в совокупности определяют предел ежедневных вознаграждений. Кроме того, пользователи получат ежедневные задания, такие как: - пройти 10 000 шагов; - Сжечь 1 800 калорий. Кроме того, для создания долгосрочной устойчивой бизнес-модели WeSleep сочетает бизнес-подходы Web3 (sleep2earn) и Web2 (b2b2c маркетплейс). Такая стратегия позволяет создать эффективный поток доходов, который затем будет реинвестирован обратно в токены экосистемы: управление и вознаграждение, чтобы сохранить их ценность в долгосрочной перспективе.`,
      projectId: "1",
    },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "15% at TGE, 3 months cliff, then 12 months linear vesting",
      projectId: "1",
    },
    {
      id: "5",
      type: "vesting",
      locale: "ru",
      value: "15% на TGE, клифф 3 месяца, далее линейный вестинг 12 месяцев",
      projectId: "1",
    },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://fundraising.gagarin.world/form/6398b27bc5d54e08cb057592 ",
      projectId: "1",
    },
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "ustd",
      icon: usdt,
    },
  ],
};

export default WESLEEP;
