import "./header.sass";
import logo from "../../assets/img/logo.svg";
import menuIcon from "../../assets/img/menu.svg";
import Button from "./button";
import Dropdown from "./dropdown";
import { useRef, useState } from "react";
import HeaderMenu from "./header.menu";
import { LINKS } from "../data/links";
import Soon from "./soon.icon";
import LanguageSelector from "./language";
import { useTranslation } from "react-i18next";

type TProps = {};

function Header({}: TProps) {
  const [opened, setOpened] = useState(false);
  const menuRef = useRef(null);
  const goTo = (selector: string) => {
    document.querySelector(selector)?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const {t} = useTranslation()
  return (
    <div className="header">
      <HeaderMenu handler={setOpened} _ref={menuRef} visible={opened} />
      <div className="header__inner">
        <a href="/" className={`header__logo-link ${opened ? "fx" : ""}`}>
          <img src={logo} alt="" className="header__logo" />
        </a>
        <div className="header__nav">
          <a
            href={LINKS.servicesLanding}
            target={"_blank"}
            rel="noreferrer"
            className="header__item"
          >
            {t('header.projects')}
          </a>
          <a onClick={()=>goTo("#products")} href={LINKS.landing} target={"_blank"}
            rel="noreferrer" className="header__item">
            Gagarin.world
          </a>
          
          <a onClick={()=>goTo(".faq")} className="header__item">
                {t("header.about")}
              </a>
          <a href={LINKS.launchpad} target={"_blank"}
            rel="noreferrer" className="header__item">
            {t("header.launchpad")}
          </a>
          <a href={LINKS.submitYourProject} target={"_blank"}
            rel="noreferrer" className="header__item">
            {t('header.submit')}
          </a>
        </div>
        <div className={`header__buttons ${opened ? "fx" : ""}`}>
          <LanguageSelector />
          <Button onClick={() => {
            window.open(LINKS.submitYourProject)
          }} className="header__button submit">{t("header.apply")}</Button>
          <Button
            className="header__button header__menu-button"
            onClick={() => {
              setOpened((state) => !state);
            }}
          >
            <div className={`menu-button ${opened ? "opened" : ""}`}>
              <div className="middle"></div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
