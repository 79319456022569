import banner from "../../../assets/img/pools/banner-hunger.png";
import token from "../../../assets/img/pools/token-hunger.svg";
import busd from "../../../assets/img/tokens/busd.svg";

const OCP = {
  id: "1",
  tokenSymbol: "MEL",
  network: "BNB Chain",
  TGEDate: 1677704400,
  visible: true,
  round: "private",
  socials: [
    {
      id: "1",
      link: "https://hungerchains.com",
      type: "Website",
      projectId: "1",
    },
    {
      id: "7",
      link: "https://t.me/hungerchains",
      type: "Telegram",
    },
    {
      id: "2",
      link: "https://twitter.com/hungerchains",
      type: "Twitter",
      projectId: "1",
    },

    {
      id: "5",
      link: "https://discord.gg/HucA2mgMCt",
      type: "Discord",
      projectId: "1",
    },


  ],
  images: [
    {
      id: "1",
      path: banner,
      type: "banner",
      projectId: "1",
    },
    {
      id: "2",
      path: '',
      type: "logo",
      projectId: "1",
    },
    {
      id: "3",
      path: token,
      type: "tokenLogo",
      projectId: "1",
    },
  ],
  translations: [
    {
      id: "1",
      type: "title",
      locale: "en",
      value: "Hunger Chains: Revolution",
      projectId: "1",
    },
    {
      id: "2",
      type: "description",
      locale: "en",
      value: `Hunger Chains universe is an upcoming first-person shooter powered by evolutionary and community-driven tools, built on the top of blockchain to offer real ownership. Find yourself challenging in tournaments, world-cups, complete special operations with your friends and Clan members to earn coins and NFT’s. Discover and collect ammunition, weapon, cities, pets, clubs, and much more!
      Skill-based community shooter & metaverse with various PvP modes for players to enjoy is very ready to launch. The main shooter is based on a social strategy focused on city control, arenas, tradings, with a deep focus on clans, alliances and social mechanics. The game's economy rewards social behavior and is designed for an endless experience created by and for the community of players.
      `,
      projectId: "1",
    },
    // {
    //   id: "3",
    //   type: "description",
    //   locale: "ru",
    //   value: `OCP токен это глобальный проект, задачей которого является построение глобальной экосистемы по принципу: «Единый мир, объединенный через знания и обмен». OCP создает систему вознаграждений, где каждый человек, который использует и поддерживает OCP, получает награду. Это могут быть токены, ваучеры от партнерских отраслей, скидки и даже участие в прибыли отдельных проектов. OCP станет будущим платежей и основой для преодоления безработицы, поддержки образования и инновационного бизнеса по всему миру.
    //   Материнской компание OCP  является некоммерческая организация OTI которая имеет 16 офисов по всему миру, включая Соединенное Королевство и Кипр. С 2012 года OTI помогла получить образование, освоить новую профессию и получить новые возможности более 5 млн человек.`,
    //   projectId: "1",
    // },
    {
      id: "4",
      type: "vesting",
      locale: "en",
      value: "20% on tge, remaining over 4 months (20% each)",
      projectId: "1",
    },
    {
      id: "5",
      type: "vesting",
      locale: "ru",
      value: "20% на TGE, остальное через 4 месяцев(20% каждый)",
      projectId: "1",
    },
    {
      id: "6",
      type: "link",
      locale: "en",
      value: "https://fundraising.gagarin.world/form/63ebcca4cbb1b69079ef1690",
      projectId: "1",
    },
  ],
  exchangeCurrency: [
    {
      id: "1",
      name: "busd",
      icon: busd,
    },
  ],
};

export default OCP;
