import { useState } from "react";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/img/arrow-right.svg";
import infoIcon from "../../assets/img/i.svg";
import globeIcon from "../../assets/img/language.svg";
import { Image, LinkName, NETWORK_ICONS, TPoolInfo, Translation } from "../../redux/gft.types";
import { useGetTranslations } from "../hooks";
import "./pool.item.sass";
import PoolModal from "./pool.modal";

type Props = {
  pool: TPoolInfo;
  index: number
};
const formatDate = (timestamp: number | "ATP") => {
  if (timestamp === 0) {
    return "TBA";
  } else if (timestamp === "ATP") {
    return "ATP"
  }
  const date = new Date(timestamp * 1000).toLocaleDateString().replaceAll("/", ".");
  return date;
};


const Pool = ({ pool, index }: Props) => {
  const [opened, setOpened] = useState(false);
  const [mouseIn, setMousein] = useState(false);
  const bannerImage = pool.images?.find(({ type }) => type == Image.BANNER)?.path;
  const tokenLogo = pool.images?.find(({ type }) => type == Image.TOKEN_LOGO)?.path;
  const logo = pool.images?.find(({ type }) => type == Image.LOGO)?.path;
  const networks = pool.network.split(", ");
  const link = useGetTranslations(Translation.LINK, pool.translations || []);
  const vesting = useGetTranslations(Translation.VESTING, pool.translations || []);
  const { t } = useTranslation();
  return (
    <div className="pool" style={
      {
        zIndex: index
      }
    }>
      <PoolModal handleClose={() => setOpened(false)} pool={pool} open={opened} />
      <div
        className="pool__frame"
        style={{
          backgroundImage: `url(${bannerImage || ""})`,
        }}
        onClick={() => setOpened(true)}
      >
        <div className="pool__top">
          <img src={logo || ""} alt="" className="pool__logo" />
          {/* <img src={networkLogo || ""} alt="" className="pool__network" /> */}
          <div className="pool__networks">
            {networks.filter(el => el.length > 0).map(el => {
                return <img src={NETWORK_ICONS[el]} alt={el} className="pool__network" />
            })}
          </div>
        </div>
        <div className="pool__info">
          <div className="pool__row">
            <p className="pool__desc">{pool.round}</p>
            <p className="pool__desc">
              <span>TGE</span> {formatDate(pool.TGEDate)}
            </p>
          </div>
          <div className="pool__row">
            <div className="pool__currency">
              {pool.tokenSymbol}
              <div className="pool__currency-box">
                <img src={tokenLogo || ""} alt="" className="pool__icon" />
                <img src={arrow} alt="" className="pool__arrow" />
                <div className="pool__exchange">
                  {pool.exchangeCurrency && pool.exchangeCurrency.map((el, i) => {
                    return (
                      <img
                        src={el.icon}
                        alt={el.name}
                        style={{ zIndex: 20 - i }}
                        className="pool__icon"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="pool__vesting"
              onMouseEnter={() => setMousein(true)}
              onMouseLeave={() => setMousein(false)}
            >
              {t("Vesting")}
              <img src={infoIcon} alt="" className="pool__vesting-icon" />
              <div className={`pool__vesting-popup ${mouseIn ? "opened" : ""}`}>{vesting}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pool__bottom">
        {pool.socials && pool.socials?.map((el) => {
          if (el.type.toLowerCase() === LinkName.Website) {
            return (
              <a href={el.link} className="pool__social">
                <img src={globeIcon} alt="" className="pool__social-icon" />
                {t("website")}
              </a>
            );
          }
        })}
        <a href={link} className="pool__social">
          <img src={globeIcon} alt="" className="pool__social-icon" />
          {t("participate")}
        </a>
      </div>
    </div>
  );
};

export default Pool;
