import { TPoolInfo, TProjectActivity } from "./gft.types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const poolsApi = createApi({
  reducerPath: "poolsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_GFT_API}`,
  }),
  endpoints: (builder) => ({
    pools: builder.query<TPoolInfo[], void>({
      query: () =>
        `/projects?filter=${JSON.stringify({
          order: ["weight DESC"],
          include: [
            { relation: "exchangeCurrency" },
            { relation: "translations" },
            { relation: "images" },
            { relation: "socials" },
          ],
          // where: {visible : true}
        })}`,
    }),
    activities: builder.query<TProjectActivity[], void> ({
      query : () => 
       `project-activities?filter=${JSON.stringify({
        include: [
          { relation: "translations" },
            { relation: "images" },
        ],
        where: {visible : true}
       })}`
    })
  }),
});

export const { usePoolsQuery, useActivitiesQuery } = poolsApi;
