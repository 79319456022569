import { Language } from "../enums";
import iEn from "../../assets/img/locale_en.svg";
import iRu from "../../assets/img/locale_ru.svg";
import cn from "classnames";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./language.sass";
import { useOutsideClick } from "./projects.hooks";

const LANGUAGE_ICON_MAP = {
  [Language.EN]: iEn,
  [Language.RU]: iRu,
};

type Props = {};

const LanguageSelector: FC<Props> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const togglePopup = () => {
    setIsOpen((state) => !state);
  };

  const changeLanguage = (language: Language) => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <button className="language-selector">
      <div
        className={cn("language-selector__item", { _open: isOpen })}
        onClick={togglePopup}
      >
        {i18n.language}
        <img
          className="language-selector__item-icon"
          src={LANGUAGE_ICON_MAP[i18n.language as Language]}
          alt={i18n.language}
        />
      </div>
      <div
        ref={ref}
        className={cn("language-selector__popup", { _open: isOpen })}
      >
        {Object.values(Language)
          .sort((a) => (a === (i18n.language as Language) ? -1 : 1))
          .map((language, i) => (
            <div
              key={"language-item" + i}
              className="language-selector__item"
              onClick={() => changeLanguage(language)}
            >
              {language}
              <img
                className="language-selector__item-icon"
                src={LANGUAGE_ICON_MAP[language]}
                alt={language}
              />
            </div>
          ))}
      </div>
    </button>
  );
};

export default LanguageSelector;
