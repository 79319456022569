import { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Translation, TTranslation } from "../redux/gft.types";
import type { RootState, AppDispatch } from "./store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useOnClickOutside(ref : any, handler : any) {
    useEffect(
      () => {
        const listener = (event : Event) => {
          if (ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }
  
  export default function useOnScreen(ref : RefObject<HTMLDivElement | SVGSVGElement | null>, threshold: number = 0.3) {
  
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting), {threshold: threshold}
    )
  
    useEffect(() => {
      if (ref && ref.current)
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }



export type TFilteredTranslation = {
    en?: string,
    ru?: string
}


const translationsToObject = (array : TTranslation[]) : TFilteredTranslation => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item.locale]: item.value,
      };
    }, initialValue);
  };



export const useGetTranslations = ( ttype : Translation, arr : TTranslation[]) => {
  const {i18n} = useTranslation()
  const [locale, setLocale] = useState<string>(i18n.language)
  useEffect(() => {
    setLocale(i18n.language)
  }, [i18n.language])
  const filtered = arr.filter(({type}) => type === ttype)

  const obj = translationsToObject(filtered)
  if(!obj.ru) {
    return obj.en
  }
  //@ts-ignore
  return obj[locale]
}